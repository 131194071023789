import {fetchData_v2, log, requestExport_v2} from "../common/app";
import {Alert} from "./alert";
import {initHeader_v2, switchHeaderBtnsVisible} from "../cameraHeader";
import {displayPrivateMask} from "../common/privateMask";
import {deviceSub, deviceUnSub} from "../common/websocket_utils";
import {fullscreenEnabled, fullscreenOff, fullscreenOn, hasFullscreenElement} from "../common/fullscreen";
import {hasAlarm, hasAttention, hasFault, hasMotion} from "../common/stateUtils"
import {getPlayer} from "../player";
import {Ptz} from "../ptzControl2";
import {sessionInfo} from "../common/sessionUtils";
import {Archive} from "../archive2";
import {widgetSvg} from "./widgetSvg";

/**
 * Класс работы с виджетами камер
 */
export class Widget {
    $svg;
    $parent;
    promtv;
    devId;
    auth;
    streamNo;
    camConf;
    videoTime;
    session;
    archive;
    player;
    ptz;
    ptzCb;
    alert;
    options = {};

    /**
     * Создание нового виджета
     * @param {HTMLElement|jquery} parent - div контейнер для виджета
     * @return Widget
     */
    constructor(parent) {
        if (parent instanceof jQuery) {
            this.$parent = parent;
        } else {
            this.$parent = $(parent);
        }

        this.$svg = $(widgetSvg());
        this.$parent
            .addClass('camera-frame')
            .append(this.$svg);

        this.switchMaximizeHandler = this.switchMaximizeHandler.bind(this);
        this.fullScreenMaximizeListener = this.fullScreenMaximizeListener.bind(this);
        this.wsMsgHandler = this.wsMsgHandler.bind(this);
    }

    /**
     * Воспроизведение живого видео. Параметры promtv, devId, auth указываются либо явно,
     * либо могут быть взяты из конфигурации камеры (поля promtv, name и auth соответственно).
     * @param {Object} params - объект с параметрами
     * @param {string} params.promtv - сервер ПромТВ
     * @param {string} params.devId - идентификатор (название) устройства
     * @param {string} params.auth - логин и пароль в виде `логин:пароль`
     * @param {Object} [params.camConf] - конфигурация камеры в формате ПромТВ
     * @param {int} [params.streamNo=1] - номер потока камеры
     * @param {function(Object)} [params.ptzCb] - колбек с ptz положением камеры
     * @param options
     * @param {boolean} [options.fullscreenDisabled] - отключение переключения в полный экран
     * @param {boolean} [options.showCloseButton] - отображение кнопки закрытия виджета
     */
    async playLive(params, options) {
        this.promtv = params.promtv || params.camConf?.promtv;
        this.devId = params.devId || params.camConf?.name;
        this.auth = params.auth || params.camConf?.auth;
        this.camConf = params.camConf;
        this.session = await sessionInfo(this.promtv, this.auth);
        this.ptzCb = params.ptzCb || function (pos) {
        };
        this.options = {};
        this.options.fullscreenDisabled = options?.fullscreenDisabled || false;
        this.options.showCloseButton = options?.showCloseButton || false;

        this.switchLoading(true);

        if (!this.$svg) {
            log('widget', 'possible widget is closed', this.promtv, this.devId);
            return;
        }

        this.init().then((isReady) => {
            if (isReady) {
                // по возможности стараемся выбрать поток с меньшим битрейтом
                this.streamNo = params.streamNo !== undefined ? params.streamNo : this.lowStreamNo();

                this._playLive();
            }
        });
    }

    lowStreamNo() {
        return Math.min(this.camConf.streams.length - 1, 1);
    }

    async _playLive() {
        if (this.archive.active) { //todo проверить
            await this.archive.switchOff();
        }

        this.switchLoading(true);

        // window.promtv.mse = this.player;
        this.player.play({
            promtv: this.promtv,
            auth: this.auth,
            camId: this.devId,
            url: this.promtv + `/device/${this.devId}/channel/${this.streamNo}/live`,
            video: this.$svg.find(`video`)[0],
        });
    }

    async init() {
        log(`widget`, `init camera`, this.promtv + '/' + this.devId);

        let ex = this.options.showCloseButton ? ['.close-btn'] : [];
        switchHeaderBtnsVisible(this.$svg, false, ex);

        this.$svg.find(`text.camera-caption, text#caption`).text(this.devId);

        if (!this?.camConf?.streams) { // проверяем была ли передана конфигурация камеры
            let answer = await fetchData_v2(`${this.promtv}/device/info/${this.devId}`, this.auth);
            this.switchLoading(false);
            if (!answer.status && this.$parent?.length) {
                this.alert?.clear();
                this.alert = new Alert({
                    parent: this.$parent[0],
                    text: `Камера или разрешения для её просмотра отсутствуют ${!!answer.error ? '(' + answer.error + ')' : ''}`
                });
                this.alert.display();

                return false;
            }

            this.camConf = {
                ...this.camConf,
                ...answer.payload,
                promtv: this.promtv,
                auth: this.auth
            };
        }

        if (!this.$svg) {
            return false;
        }

        this.$svg.find(`text.camera-caption, text#caption`).text(`${this.camConf.displayName}`);

        switchHeaderBtnsVisible(this.$svg, true);

        if (!this.player) {
            this.player = getPlayer();
            this.player.onPlayListener(() => {
                log(`widget`, `player on play listener`, this.promtv, this.devId);
                this.switchLoading(false);
            })
        }
        if (!this.archive) {
            this.archive = new Archive(this.promtv, this.auth, this.$svg, this.camConf, this.player);
        }

        initHeader_v2(this.$svg, this.camConf,//todo проверить не остаются ли слушатели после удаления
            //переход в архив
            (enabled) => {
                log(`widget`, `switch archive enabled:, ${enabled}`, this.promtv, this.devId);
                this.archive.switch(enabled);

                if (!enabled) {
                    this._playLive();
                }
            },
            //кнопка экспорта текущего кадра
            () => {
                let expReqBody = {"devId": this.camConf.name, "source": this.archive.archiveNo};

                if (this.archive.archiveNo > -1 && !!this.archive.videoTime) {
                    expReqBody.start = this.archive.videoTime.utc().format('YYYYMMDD[T]HHmmss');
                }

                requestExport_v2(this.$svg, this.archive.exportQueue, this.camConf, expReqBody);
            });

        if (this.camConf.ptzIsActive) {
            this.ptz = new Ptz(this.$svg, this.promtv, this.auth, this.devId, this.session,
                this.camConf.ptzDefaultPosition, this.camConf.presets);
        }

        // увеличение во весь экран
        this.$svg.find(`.maximize-btn`).click(this.switchMaximizeHandler);
        this.$svg.dblclick(this.switchMaximizeHandler);
        if (!!this.ptz) {
            this.ptz.onSwitchListener(isActive => {
                if (isActive) {
                    this.$svg.unbind('dblclick', this.switchMaximizeHandler);
                } else {
                    this.$svg.dblclick(this.switchMaximizeHandler);
                }
            })
        }

        // document.addEventListener('fullscreenchange', this.fullScreenMaximizeListener);

        log(`widget`, `header set for camera`, this.devId);

        // check and display private mask (live)
        if (!!this.camConf?.privacyMask?.params?.protectLive) {
            if (!this.$svg.parent().hasClass('params-camera-wrapper')) {
                displayPrivateMask(this.camConf, this.$svg);
            }
        }

        // check ptz control permissions
        this.checkPermissions('camera_control', 'ptz').then(allowed => {
            if (!this.$svg) { // запрос асинхронный, ответ может придти когда уже виджет будет удалён
                return;
            }

            log(`widget`, 'camera_control ptz allowed:', allowed);

            const $ptzBtn = this.$svg.find(`.header .ptz-btn`);
            if (allowed) {
                if (this.camConf.ptzIsActive) {
                    $ptzBtn.removeClass('disabled');
                    $ptzBtn.off('click');
                    $ptzBtn.on('click', (e) => {
                        this.ptz.switch(this.ptzCb);
                    });
                }
                return;
            }
            $ptzBtn.addClass('disabled');
        });

        deviceSub(this.promtv, this.devId, this.auth, this.wsMsgHandler);

        return true;
    }

    async checkPermissions(o, a) {
        let resp = await fetchData_v2(`${this.promtv}/user/check_rights`, this.auth, {
            "subj": this.session.user,
            "obj": o,
            "act": a
        });

        return resp.status && resp.payload;
    }

    switchMaximizeHandler(e) {
        if (this.options.fullscreenDisabled) {
            return;
        }

        const isParentDiv = this.$parent.is('div');
        const $maxBtn = this.$svg.find(`.maximize-btn`);

        if (hasFullscreenElement()) {
            if (!isParentDiv) {
                this.$svg.appendTo(this.$parent);
                $('div.camera-frame').remove();
            }

            $maxBtn.removeClass('active');
            $('.camera-frame.fullscreen').removeClass('fullscreen');
            fullscreenOff();

            // меньший битрейт
            if (!this.archive.active && this.streamNo === 0 && this.camConf.streams.length > 1) {
                this.streamNo = this.lowStreamNo();
                this._playLive()
            }
        } else {
            if (fullscreenEnabled()) {
                $maxBtn.addClass('active')

                if (!isParentDiv) {
                    let $divFrame = $('<div class="camera-frame fullscreen"></div>');
                    $('body').append($divFrame);
                    fullscreenOn($divFrame[0]);
                    this.$svg.appendTo($divFrame);
                } else {
                    this.$parent.addClass('fullscreen');
                    fullscreenOn(this.$parent[0]);
                }

                // битрейт получше
                if (!this.archive.active && this.streamNo > 0) {
                    this.streamNo = 0;
                    this._playLive();
                }
            }
        }

        /*if (this.$svg.attr('data-mode') === 'archive') { ///todo проверить на экране с несколькими виджетами
            let $exSpCnt = this.$svg.find(`.speed-control`);
            let start = this.archive.archiveInterval.start;
            let end = this.archive.archiveInterval.end;
            let archNo = this.archive.currSource;
            let data = this.archive.timeLine.data();
            /!*displayArchiveInfo(devId, start, end, archNo).then(() => {//todo
                $exSpCnt.remove();
            });*!/

            createTimeline(this.archive, this.$svg, this.camConf, archNo, data, start, end, !!getFullscreenElement() ? document.body : $camWrap[0]); // инверсия
        }*/

        this.alert?.clear();
    }

    fullScreenMaximizeListener(e) {
        if (this.options.fullscreenDisabled) {
            return;
        }

        this.clearAlerts();

        let $btn = this.$parent.find(`.maximize-btn`);
        hasFullscreenElement() ? $btn.addClass('active') : $btn.removeClass('active');
    }

    wsMsgHandler(msg) {
        switch (msg.type) {
            case 'state':
                let $border = this.$svg.find(`.ptz-area`);
                let $bell = this.$svg.find(`.alarm-bell`);

                if (hasFault(msg.data.state)) {
                    $border.addClass('fault');
                } else {
                    $border.removeClass('fault');
                }

                if (hasAlarm(msg.data.state)) {
                    $border.addClass('alarm');
                    $bell.addClass(`active`);
                    $bell.on('click', () => {
                        fetchData_v2(`${this.promtv}/device/state/change/${this.devId}/normal`, this.auth);
                    })
                } else {
                    $border.removeClass('alarm');
                    $bell.removeClass(`active`);
                    $bell.off();
                }

                if (hasMotion(msg.data.state)) {
                    this.$svg.find(`.motion-icon`).show();
                } else {
                    this.$svg.find(`.motion-icon`).hide();
                }

                if (hasAttention(msg.data.state)) {
                    this.$svg.find(`.share-btn`).addClass('active');
                } else {
                    this.$svg.find(`.share-btn`).removeClass('active');
                }

                break;

            case 'ptz_occupation':
                this.ptz.occupationHandler(msg);
                break;

            case 'ptz_ask_user':
                this.ptz.askUserHandler(msg);
                break;

            case 'ptz_hide_dialog':
                this.ptz.hideDialogHandler(msg);
                break;
        }
    }

    switchLoading(active) {
        if (!!active) {
            this.$svg?.addClass('loading');
        } else {
            this.$svg?.removeClass('loading');
        }
    }

    close() {
        if (this.player) {
            this.player.destroy();
        }
        this.player = null;

        this.alert?.clear();
        this.alert = null;

        this.clearHandlers();
        this.clearHeaderListeners();

        if (this.$svg) {
            this.$svg.remove();
        }
        this.$svg = null;
        this.$parent = null;

        if (!!this.archive) {
            this.archive.close();
        }
        this.archive = null;

        if (!!this.ptz) {
            this.ptz.close();
        }
        this.ptz = null;

        // this.clearVars();

        deviceUnSub(this.promtv, this.devId, this.auth, this.wsMsgHandler);

        log(`widget`, this.promtv, this.devId, 'closed')
    }

    clearHandlers() {
        this.$svg.find(`.maximize-btn`).off('click', this.switchMaximizeHandler);
        this.$svg.off('dblclick', this.switchMaximizeHandler);
        // document.removeEventListener('fullscreenchange', this.fullScreenMaximizeListener);
    }

    clearAlerts() {
        this.$parent.find(`.widget-alert-wr`).remove();
    }

    clearHeaderListeners() {
        this.$svg.find('.alarm-bell').off();
        this.$svg.find('.share-btn').off();
        this.$svg.find('.export-btn').off();
        this.$svg.find('.archive-btn').off();
        this.$svg.find('.ptz-btn').off();
        this.$svg.find('.maximize-btn').off();
        this.$svg.find('.close-btn').off();
    }

    $svgEl() {
        return this.$svg;
    }

    clearVars() {
        this.$svg = null;
        this.$parent = null;
        this.camConf = null;
        this.player = null;
    }

    /**
     *
     * @return {{devId, auth, promtv}}
     */
    connParams() {
        return {
            promtv: this.promtv,
            devId: this.devId,
            auth: this.auth,
        }
    }
}