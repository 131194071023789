function createSpeedControl($svg, playSpd) {
    let $smb = $(`
        <svg class="speed-control" width="130" height="23" viewBox="0 0 147 23" fill="none"  xmlns="http://www.w3.org/2000/svg"> 
            <g class="spd-btn back-4x" data-spd="-4">
                <rect height="20" width="24" />
                <path d="M12 11.5L23.227 21.4593L23.227 1.54071L12 11.5Z" />
                <path d="M0 11.5L11.227 21.4593L11.227 1.54071L0 11.5Z" />
                <path d="M6 11.5L17.227 21.4593L17.227 1.54071L6 11.5Z" /> 
            </g>
            <g class="spd-btn back-2x" data-spd="-2">
                <rect x="26" y="0" height="20" width="24" />
                <path d="M37.9695 11.5L49.1964 21.4593L49.1964 1.54071L37.9695 11.5Z" />
                <path d="M26.9695 11.5L38.1964 21.4593L38.1964 1.54071L26.9695 11.5Z" />
            </g>
            <g class="spd-btn back-1x" data-spd="-1">
                <rect x="52" y="0" height="20" width="14" />
                <path d="M52.9387 11.5L64.1657 21.4593L64.1657 1.54071L52.9387 11.5Z" />    
            </g>
            <g class="spd-btn pause" data-spd="0">
                <rect x="68" y="0" height="20" width="14" />                
                <g class="pause_btn" >
                    <rect x="68" y="1" width="4" height="21" />
                    <rect x="75" y="1" width="4" height="21" />  
                </g>
            </g>
            <g class="spd-btn fwd-1x" data-spd="1" >
                <rect x="80" y="0" height="20" width="14" />
                <path d="M93.9692 11.5L82.7423 21.4593L82.7423 1.54071L93.9692 11.5Z" />    
            </g>
            <g class="spd-btn fwd-2x" data-spd="2" > 
                <rect x="96" y="0" height="20" width="24" />       
                <path d="M108.938 11.5L97.7115 21.4593L97.7115 1.54071L108.938 11.5Z" />
                <path d="M119.938 11.5L108.712 21.4593L108.712 1.54071L119.938 11.5Z" />
            </g>
            <g class="spd-btn fwd-4x" data-spd="4" >
                <rect x="122" y="0" height="20" width="24" />
                <path d="M134.908 11.5L123.681 21.4593L123.681 1.54071L134.908 11.5Z" />
                <path d="M146.908 11.5L135.681 21.4593V1.54071L146.908 11.5Z" />
                <path d="M140.908 11.5L129.681 21.4593V1.54071L140.908 11.5Z" />
            </g>    
            <rect class="non-active" height="21" width="146"  />   
        </svg> 
    `);

    $svg.append($smb);
    $smb
        .attr('x', 470)
        .attr('y', 332);

    $smb.find(`g.spd-btn`).each(function () {
        $(this).on('click', (e) => {
            switchBtnActive($smb, e.currentTarget);
            playSpd(parseInt($(this).data('spd')));
        });
    });

    return $smb;
}

function reset(smb) {
    $(smb).find('g').each(function () {
        $(this).removeClass('active');
    });
}

function switchBtnActive($smb, target) {
    reset($smb);
    $(target).addClass('active');
}

function switchControl(smb, mode) {
    $(smb).find(`rect.non-active`)
        .css('display', mode ? 'none' : '');
}

function init($smb) {
    reset();
    switchControl($smb, false);
}

function defaultState($smb) {
    switchBtnActive($smb, $smb.find(`.fwd-1x`));
    switchControl($smb, true);
}

export {createSpeedControl, init, defaultState}