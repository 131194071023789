import {log} from "./app";

export function onClassChange(node, callback) {
    let lastClassString = node.classList.toString();

    const mutationObserver = new MutationObserver((mutationList) => {
        for (const item of mutationList) {
            if (item.attributeName === "class") {
                const classString = node.classList.toString();
                if (classString !== lastClassString) {
                    log(`tools`, 'class changed', {tab: node.id, classes: classString});
                    callback(classString);
                    lastClassString = classString;
                    break;
                }
            }
        }
    });

    mutationObserver.observe(node, { attributes: true });

    return mutationObserver;
}
export function onClassChangeRemove(observer) {
    if (observer) {
        observer.disconnect();
    }
}

export function nameFromAuth(auth) {
    return auth && auth.includes(':') ? auth.split(':')[0] : auth;
}

/**
 * Проверка какая ОС используется по возможности плеера проигрывать формат apple
 * @return {boolean}
 */
export function isIOS() {
    const testVideo = document.createElement('video');
    const res = testVideo.canPlayType('application/vnd.apple.mpegurl');
    testVideo.remove();
    return !!res.length;
}