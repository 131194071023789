import dayjs from "dayjs";
import {cache, stopPreviousArchiveVideo} from "../archive";
import {registerRight} from "./permissions";
import {resetAllPtzSessions} from "../ptzControl";
import {appSession, auth, requestAppSession} from "./session";

export let iceServers = [];

let server; // RtspToWeb
let wsPort;
let serverWs;

let debug = true;
log(`app`, 'debugging enable:', debug);

requestAppSession();


window.onbeforeunload = (ev) => {
    resetAllPtzSessions();
    for (const cameraId in cache) {
        stopPreviousArchiveVideo(cameraId);
    }
}

$(document).ready(async () => {
    log('front', 'document ready');

    //register rights
    registerRight({
        name: 'camera_control',
        description: 'Управление камерой',
        data: [
            {name: 'ptz', description: 'Изменение PTZ'},
        ]
    });
})

export async function fetchData(url, post) {
    log(`api`, `--> requesting ${url}`, 'body:', post);

    let scadaHeader;
    try {
        scadaHeader = {'Scada-User': USER_INFO.login};
    } catch (e) {
        scadaHeader = {};
    }

    let data, err;
    await fetch(server + url, {
        method: post ? 'POST' : 'GET',
        headers: {
            ...scadaHeader,
            "Authorization": `Basic ${btoa(auth)}`,
            'Content-Type': 'application/json'
        },
        body: post ? JSON.stringify(post) : undefined

    }).then(async resp => {
        data = await resp.json();
        log(`api`, `<-- received: `, data);

    }).catch((e) => {
        logError('api', 'error', e);
        err = {status: 0, payload: e}
    });

    return await err ? err : data;
}

/**
 *
 * @param url
 * @param auth
 * @param post
 * @returns {Promise<*>}
 */
export async function fetchData_v2(url, auth, post) {
    log(`api`, `--> requesting ${url}`, post);

    let headers = {};
    if (auth) {
        headers['Authorization'] = `Basic ${btoa(auth)}`;
    } else {
        headers['Authorization'] = `Basic ${btoa("demo:demo")}`; // todo для обратной совместимости
        try {
            headers['Scada-User'] = USER_INFO.login;
        } catch (e) {
        }
    }

    let data, err;
    await fetch(url, {
        method: post ? 'POST' : 'GET',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: post ? JSON.stringify(post) : undefined

    }).then(async resp => {
        if (resp.status >= 200 && resp.status < 300) {
            data = await resp.json();
            log(`api`, `<-- received: `, data);
        } else {
            err = {status: 0, error: resp.statusText};
        }

    }).catch((e) => {
        // logError('api', 'error', e);
        err = {status: 0, error: e.message}
    });

    return await err ? err : data;
}

export function switchDebug(mode) {
    debug = mode;
    $(`.play-time`).css('display', 'none');
}

export function log(module, ...details) {
    if (debug) {
        console.log(dayjs(new Date()).format('YYYY.MM.DD HH:mm:ss:SSS [::]'), `[${textFixLength(module)}]`, ...details);
    }
}

export function logWarn(module, ...details) {
    console.warn(dayjs(new Date()).format('YYYY.MM.DD HH:mm:ss:SSS [::]'), `[${textFixLength(module)}]`, ...details);
}

export function logError(module, ...details) {
    console.error(dayjs(new Date()).format('YYYY.MM.DD HH:mm:ss:SSS [::]'), `[${textFixLength(module)}]`, ...details);
}

function textFixLength(text) {
    let len = 8;
    while (text.length < len) {
        text += ' ';
    }
    if (text.length > len) {
        text = text.substring(0, len);
    }
    return text;
}

export function setServer(url = document.location.origin) {
    server = url;
    log(`app`, `server changed to:`, server);
    setServerWs();
}

export function setWsPort(port) {
    wsPort = port;
    log(`app`, `ws port set to`, wsPort);
}

function setServerWs() {
    const srvUrl = new URL(server);
    let prot = srvUrl.protocol.startsWith('https') ? 'wss' : 'ws';
    let port = wsPort === undefined ? srvUrl.port : wsPort;
    let authData = auth === undefined ? '' : auth + '@';

    serverWs = `${prot}://${authData}${srvUrl.hostname}:${port}`;

    log(`app`, `ws server changed to`, hideUrlSensData(serverWs));
}

function setExportIconActive($svg, active = true) {
    // log(`change export icon to`, active);
    let $expBtn = $svg.find(`.export-btn`);
    if (active) {
        $expBtn.addClass('active');
    } else {
        $expBtn.removeClass('active');
    }
}

export async function requestExport(reqBody) {
    let name = reqBody.devId;
    let $svg = $(cache[name].svg);
    let url = `/device/export`;
    log(`app`, `request PromTV media export for cam ${name}, ${url}`, reqBody);

    cache[name].exportQueue.push(true);
    setExportIconActive($svg, name);

    fetchData(url, reqBody)
        .then(data => {
            cache[name].exportQueue.shift();
            if (cache[name].exportQueue.length === 0) {
                setExportIconActive($svg, name, false);
            }

            if (data.status) {
                url = data.payload;
                downloadFile(url);
                setTimeout(() => {
                    fetchData(`/device/export/delete`, {path: url.replace(location.origin + '/', '')})
                }, 5000);
            }
        });
}

/**
 *
 * @param $svg
 * @param queue
 * @param cam
 * @param reqBody
 * @returns {Promise<void>}
 */
export async function requestExport_v2($svg, queue, cam, reqBody) {
    let name = reqBody.devId;
    let url = `${cam.promtv}/device/export`;
    log(`app_v2`, `request PromTV media export for cam ${name}, ${url}`, reqBody);

    queue.push(true);
    setExportIconActive($svg);

    fetchData_v2(url, cam.auth, reqBody)
        .then(data => {
            queue.shift();
            if (!queue.length) {
                setExportIconActive($svg, false);
            }

            if (data.status) {
                let shortUrl = data.payload;
                downloadFile( cam.promtv + '/' + shortUrl);
                setTimeout(() => {
                    fetchData_v2(`${cam.promtv}/device/export/delete`, cam.auth,{path: shortUrl});
                }, 5000);
            }
        });
}

export function downloadFile(url) {
    log(`app`, `downloading file, url`, url);

    let a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    a.target = "_blank";
    a.style = 'display: none;';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

export function hideUrlSensData(url) {
    if (!url || url.length === 0) {
        return
    }

    let fAuth = url.match(/\/\/(.+@)?/)[1];
    return fAuth === undefined ? url : url.replace(fAuth, '*');
}

export function setIceservers(newIceServers) {
    if (iceServers.length === 0) {
        iceServers = newIceServers;
        log(`app`, 'set ICE servers', newIceServers);
    }
}

export function userLogout() {
    resetAllPtzSessions();
}

export function timeFromUnix(unix, layout) {
    return dayjs.unix(unix).local().format(layout);
}

export {server, serverWs, wsPort, debug};