import {debug, fetchData_v2, log, requestExport_v2} from "./common/app";
import TimelinesChart from "./timelines-chart/src/index";
import {requestAndPrepareTimeLineData} from "./timeLineData";
import {createSpeedControl, defaultState} from "./speedControls";

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import {CamScrFAwBtn} from "./components/camScrButton";
import {Dropdown} from "./components/dropdown";
import {DateRangePicker} from "./components/daterangepicker";
import {Loader} from "./components/loader";
import {getFullscreenElement} from "./common/fullscreen";
import {_playArchive, _playLive} from "./player";

dayjs.extend(customParseFormat);

dayjs.extend(utc)

export let cache = {};

export async function switchArchive(cameraId, isSwitched) {
    if (isSwitched) {
        switchArchiveOn(cameraId);
    } else {
        await switchArchiveOff(cameraId);

        cache[cameraId].curSource = -1;
        await stopPreviousArchiveVideo(cameraId);
        _playLive(cameraId);
    }
}

function switchArchiveOn(cameraId) {
    log(`archive`, `switch archive on`, cameraId);

    let $svg = $(cache[cameraId].svg);
    $svg.attr(`data-mode`, 'archive');
    $svg.find(`.archive-btn`).addClass('active');

    let $ptzControl = $(cache[cameraId].svg).find('.ptz-control');
    let timeLineHideBtn;

    cache[cameraId].archiveInterval = {
        start: dayjs().subtract(3, 'h').toDate(),
        end: dayjs().local().toDate(),
    }

    displayArchiveInfo(cameraId, cache[cameraId].archiveInterval.start, cache[cameraId].archiveInterval.start.end).then();

    // display archive select btn
    let archiveSelBtn = new CamScrFAwBtn({
        defClasses: "wic archive-sel-btn",
        x: 576,
        y: 8,
        parent: $ptzControl[0],
    }).display();

    let archiveList = [];
    for (const [id, archive] of Object.entries(cache[cameraId].params.archives)) {
        if (archive.isActive) {
            archiveList.push({
                name: archive.name,
                action: () => {
                    log(`archive`, `switch to archive`, archive);
                    let $exSpCnt = $(cache[cameraId].svg).find(`.speed-control`);
                    let start = cache[cameraId].archiveInterval.start;
                    let end = cache[cameraId].archiveInterval.end;
                    displayArchiveInfo(cameraId, start, end, parseInt(id)).then(() => {
                        $exSpCnt.remove();
                    });
                }
            });
        }
    }

    let archiveDropDown = new Dropdown({
        appearance: 'ld',
        initiator: archiveSelBtn.el,
        items: archiveList,
        freeze: true,
        parent: $svg.parent()[0],
        selectedIndexSupplier: () => cache[cameraId].curSource,
    });

    let isTimeLineShown = true;
    timeLineHideBtn = new CamScrFAwBtn({
        defClasses: "wic timeline-hide-btn",
        x: 576,
        y: 350,
        parent: cache[cameraId].svg,
        action: () => {
            if (isTimeLineShown) {
                $svg.find('.time-chart').hide();
                $svg.find('.speed-control').hide();
            } else {
                $svg.find('.time-chart').show();
                $svg.find('.speed-control').show();
            }
            isTimeLineShown = !isTimeLineShown;
        }
    }).display();
}

export async function switchArchiveOff(cameraId) {
    log(`archive`, `switch archive off`, cameraId);

    if (!(cameraId in cache) || !('svg' in cache[cameraId])) {
        return;
    }

    let $svg = $(cache[cameraId].svg);
    $svg.attr(`data-mode`, '');
    $svg.find(`.archive-btn`).removeClass('active');

    //тестовая надпись с временем архива
    if ('tt' in cache[cameraId]) {
        $(cache[cameraId].tt).css('display', 'none');
    }

    $svg.find('.ptz-control').empty();
    $(`.my-dropdown`).remove();
    $svg.find('.timeline-hide-btn').remove();
    $svg.find(`.time-chart`).empty().show();
    $svg.find(`.speed-control`).empty();
}

export function checkAndSwitchArchiveOff(camId) {
    if (!(camId in cache) || !('svg' in cache[camId])) {
        return;
    }

    let $svg = $(cache[camId].svg);
    let mode = $svg.attr('data-mode');
    if (mode.includes('archive')) {
        switchArchiveOff(camId);
    }
}

export async function displayArchiveInfo(cameraId, start, end, archiveNo = 0) {
    let svg = cache[cameraId].svg;

    let loader = new Loader();
    loader.show(svg);

    let data = await requestAndPrepareTimeLineData(cameraId, start, end, archiveNo);
    log(`archive`, 'timeline data ', data);

    loader.hide();

    let rootElement = !!getFullscreenElement() ? getFullscreenElement() : document.body;

    cache[cameraId] = {
        ...cache[cameraId],
        timeLine: createTimeline(svg, cameraId, archiveNo, data, start, end, rootElement),
        speedControl: createSpeedControl(svg, (spd) => changePlay(cameraId, spd, archiveNo)),
        curSource: archiveNo,
    };
}

export function createTimeline(svg, cameraId, archiveNo, data, start, end, rootElement = document.body) {
    let $timeLineEl = $(svg).find(`.time-chart`);
    $timeLineEl.empty();

    return TimelinesChart()
        .onSegmentClick(segment => segmentClickHandler(segment, cameraId, archiveNo))
        .onMediaExport(mediaExportHandler(svg, cameraId, archiveNo))
        .onOverviewContext(overviewContextHandler(svg, cameraId, archiveNo))
        .zoomX([start, end])
        .width(600)
        .data(data)
        .rootElement(rootElement)
        ($timeLineEl[0])
}

function segmentClickHandler(segment, camId, archiveNo) {
    log(`archive`, "segment clicked: ", segment);

    let speed = 1;
    let start = segment.timeRange[0];

    defaultState(cache[camId].speedControl);
    playArchive_(camId, start, speed, 0, archiveNo);
}

function mediaExportHandler(svg, camId, archiveNo) {
    return function (event, segment) {
        new Dropdown({
            appearance: 'ru',
            parent: svg.parentElement,
            items: [{
                name: 'Экспорт',
                action: () => {
                    let reqBody = {
                        "devName": camId,
                        "start": dayjs(segment.timeRange[0]).utc().format('YYYYMMDD[T]HHmmss'),
                        "end": dayjs(segment.timeRange[1]).utc().format('YYYYMMDD[T]HHmmss'),
                        "source": archiveNo,
                    }
                    requestExport(reqBody);
                    log(`archive`, `export video`, reqBody);
                }
            }],
        }).display(event);
    }
}

function overviewContextHandler(svg, camId, archiveNo) {
    return function (e) {
        new Dropdown({
            appearance: 'ru',
            parent: $(svg).parent()[0],
            items: [{
                name: 'Изменить интервал',
                action: function () {
                    $(`.daterangepicker:hidden`).remove();
                    new DateRangePicker(e.target, {
                            timePicker: true,
                            timePicker24Hour: true,
                            startDate: cache[camId].archiveInterval.start,
                            endDate: cache[camId].archiveInterval.end,
                            maxDate: dayjs().toDate(),
                            opens: "center",
                            drops: "up",
                            parent: /*document.body,*/ $(svg).parent()[0],
                        }, (start, end, label) => {
                            let loader = new Loader();
                            loader.show(svg);
                            requestAndPrepareTimeLineData(camId, start, end, archiveNo).then(data => {
                                log(`archive`, 'change overview, timeline data', data);
                                cache[camId].timeLine.overviewDomain([start, end]);
                                cache[camId].timeLine.data(data);
                                cache[camId].archiveInterval.start = start;
                                cache[camId].archiveInterval.end = end;
                                loader.hide();
                            });
                        }
                    ).show();
                }
            }]
        }).display(e);
    }
}

export async function stopPreviousArchiveVideo(cameraId) {
    if ('session' in cache[cameraId]) {
        cache[cameraId].timeLine.dateMarker(undefined);
    }
}

export async function playArchive_(cameraId, start, speed = 1, prePlay = 0, archiveNo = 0) {
    log(`archive`, `requesting archive video for camera ${cameraId},  from ${start}, speed ${speed}`);

    await stopPreviousArchiveVideo(cameraId);

    let startDayjs = dayjs(start).utc().subtract(prePlay * speed, 'ms');
    let startDateTime = startDayjs.format('YYYYMMDD[T]HHmmss[.]SSS');

    // let rtspUrl = `/device/${cameraId}/archive/${archiveNo}/${startDateTime}/${speed}/webrtc`;
    // log(`archive`, `webRtc archive stream url:`, rtspUrl);

    _playArchive(cameraId, archiveNo, startDateTime, speed, undefined, (videoTime) => {
        let dayjsTime = dayjs.utc(videoTime, "YYYYMMDD_HHmmss.SSS").local();

        if (!!cache[cameraId].videoTime) {
            let timeDiff = Math.abs(dayjsTime.toDate().getUTCSeconds() - cache[cameraId].videoTime.toDate().getUTCSeconds());
            if (timeDiff > 5) {
                log(`archive`, 'time diff very large, sec', timeDiff);
                // return
            }
        }

        cache[cameraId].timeLine.dateMarker(dayjsTime.toDate());
        displayArchiveTime(dayjsTime);

        cache[cameraId].videoTime = dayjsTime;
    })

    function displayArchiveTime(startDayjs) {
        //тестовая надпись с временем архива
        if (debug) {
            $(cache[cameraId].tt)
                .css('display', '')
                .text(startDayjs.format('YYYY-MM-DD[ ]HH[:]mm[:]ss[.]SSS'));
        }
    }
}

export async function changePlay(cameraId, speed, archiveNo = 0) {
    let cid = cache[cameraId]?.webrtc?.cid || cache[cameraId]?.mse.cid;

    if (!cid) {
        log(`archive`, `change play error, cid =`, cid);
        return;
    }

    await fetchData(`/device/${cameraId}/archive/${archiveNo}/control/${speed}/${cid}`);
}
