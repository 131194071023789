import {PsmWebSocket} from "./websocket2";
import {log} from "./app";
import {nameFromAuth} from "./tools";

/**
 * Подписка на изменение состояния устройств с автоматической переподпиской на события устройств в ПромТВ при
 * пропадании соединения.
 * @param promtv {string} - сервер ПромТВ
 * @param devId {string} - имя (идентификатор устройства/камеры)
 * @param auth {string} - логин:пароль
 * @param cb {function(Object)} - колбек с возвращаемым сообщением (БЕЗ ФИЛЬТРА ПО УСТРОЙСТВУ)
 */
export function deviceSub(promtv, devId, auth, cb) {
    log(`ws utils`, promtv, `(${nameFromAuth(auth)}) subscribe to `, devId);

    let pws = getPws(promtv, auth);

    pws.subDev(devId, cb);
}
/**
 * Отмена подписки на изменение состояния устройств.
 * @param promtv {string} - сервер ПромТВ
 * @param devId {string} - имя (идентификатор устройства/камеры)
 * @param auth {string} - логин:пароль
 * @param cb {function(Object)} - колбек с возвращаемым сообщением (с фильтром по устройству)
 */
export function deviceUnSub(promtv, devId, auth, cb) {
    log(`ws utils`, promtv, `(${nameFromAuth(auth)}) unsubscribe from `, devId);

    let pws = getPws(promtv, auth);

    pws.unsubDev(devId, cb);
}

/**
 * Подписка на все события и изменение состояния websocket-а.
 * @param promtv {string} - сервер ПромТВ
 * @param auth {string} - логин:пароль
 * @param info {string} - комментарий для лога
 * @param cb {function(Object)} - колбек с сообщением, поле type которого может принимать следующие значения:
 * {type: "connect" | "reconnect" | "disconnect}
 */
export function wsStateSub(promtv, auth, info, cb) {
    log(`ws utils`, promtv, `(${nameFromAuth(auth)}) subscribe to ws connection state`,  info);

    let pws = getPws(promtv, auth);

    pws.subscribeWsMsg(cb, info);
}

/**
 * Отмена подписки на все события и изменение состояния websocket-а.
 * @param promtv {string} - сервер ПромТВ
 * @param auth {string} - логин:пароль
 * @param info {string} - комментарий для лога
 * @param cb {function(Object)} - колбек с сообщением, поле type которого может принимать следующие значения:
 * {type: "connect" | "reconnect" | "disconnect}
 */
export function wsStateUnSub(promtv, auth, info, cb) {
    log(`ws utils`, promtv, `(${nameFromAuth(auth)}) unsubscribe from ws connection state`,  info);

    let pws = getPws(promtv, auth);

    pws.unsubscribeWsMsg(cb);
}

/**
 * Подписка на события дежурного окна с автоматической переподпиской на события устройств в ПромТВ при
 * пропадании соединения.
 * @param promtv {string} - сервер ПромТВ
 * @param auth {string} - логин:пароль
 * @param info {string} - комментарий для лога
 * @param cb {function(Object)} - колбек с сообщением, поле type которого может принимать следующие значения:
 * {type: "connect" | "reconnect" | "disconnect}
 */
export function dutySub(promtv, auth, info, cb) {
    log(`ws utils`, promtv, `(${nameFromAuth(auth)}) subscribe to duty events`, info);

    let pws = getPws(promtv, auth);

    pws.subDutyEvents(cb);
}

export function sendMsgExt(promtv, auth, msg) {
    let pws = getPws(promtv, auth);
    pws.sendMsg(msg);
}

function getPws(promtv, auth) {
    let backComp = false;
    if (!auth) {
        auth = 'demo:demo';
        backComp = true;
    }

    let pws = window.promtv?.servers?.[promtv]?.[auth]?.pws;
    if (!pws) {
        pws = new PsmWebSocket();

        window.promtv = {
            ...window.promtv,
            servers: {
                ...window.promtv?.servers,
                [promtv]: {
                    ...window.promtv?.servers?.[promtv],
                    [auth]: {
                        pws: pws,
                    }
                }
            }
        }

        pws.open(promtv, auth, backComp);
    }
    return pws;
}
