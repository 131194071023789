import {cache} from "./archive";
import {fetchData, fetchData_v2} from "./common/app";

export function initHeader(camera, archCb, expCb) {
    let $svg = $(cache[camera.name].svg);

    if (Object.keys(camera.archives).length > 0) {
        addArchiveButton($svg, archCb);
        addArchiveTimeText($svg);
        addExportButton(camera, $svg, expCb);
        addShareButton(camera, $svg);

    } else {
        $svg.find(`.archive-btn`).css('visibility', 'hidden');
    }
}

export function initHeader_v2($svg, cameraConf, archCb, expCb) {
    if (Object.keys(cameraConf.archives).length > 0) {
        addArchiveButton($svg, archCb);
        addArchiveTimeText($svg);
        addExportButton(cameraConf, $svg, expCb);
        addShareButton_v2(cameraConf, $svg);

    } else {
        $svg.find(`.archive-btn`).css('visibility', 'hidden');
    }
}

function addArchiveTimeText($svg) {
    let tt = document.createElementNS("http://www.w3.org/2000/svg", "text");
    $(tt)
        .text(`2023-02-06 22: 00: 00`)
        .attr(`x`, `5`)
        .attr(`y`, `70`)
        .attr(`fill`, 'white')
        .css('display', 'none')
        .addClass('play-time')
        .appendTo($svg);

    // cache[$svg.attr(`id`)].tt = tt;
}

function addArchiveButton($svg, callback) {
    $svg.find(`.archive-btn`).off('click');
    $svg.find(`.archive-btn`).on('click', async (e) => {
        let mode = $svg.attr('data-mode');

        if (mode.length === 0) {
            setOtherBusy($svg, e.currentTarget);
            callback(true);

        } else if (mode.includes('archive')) {
            resetBusy($svg);

            $svg.find(`svg.speed-control`).remove();

            $(`.chart-tooltip`).remove();
            $(`.custom-cm`).remove();

            callback(false);
        }
    });
}

function addExportButton(camera, $svg, expCb) {
    //todo отключён экспорт для rtsp до реализации
    $svg.find(`.export-btn`).off('click');
    if (camera.type === 'cam-itv') {
        $svg.find(`.export-btn`)
            .removeClass('disabled')
            .on('click', () => {
                if (expCb) {
                    expCb()
                }
            });
    } else {
        $svg.find(`.export-btn`).addClass('disabled');
    }
}

function addShareButton(camera, $svg) {
    $svg.find('.share-btn').off('click');
    $svg.find('.share-btn').on('click', ()=> {
        fetchData(`/device/attention/${camera.name}/active`);
    })
}

function addShareButton_v2(camera, $svg) {
    $svg.find('.share-btn').off('click');
    $svg.find('.share-btn').on('click', ()=> {
        fetchData_v2(`${camera.promtv}/device/attention/${camera.name}/active`, camera.auth);
    })
}

export function setOtherBusy($svg, activeEl) {
    $svg.find(`.r-btn`).each(function () {
        if (this !== activeEl) {
            $(this).addClass(`busy-btn`);
        }
    });
}

export function resetBusy($svg) {
    $svg.find(`.r-btn`).each(function () {
        $(this).removeClass(`busy-btn`);
    });
}

/**
 * Отображает или скрывает кнопки виджета
 * @param $svg {jquery} - элемент виджета
 * @param visible {boolean} - отобразить (true) или скрыть (false)
 * @param exceptions {Array[string]} - исключения в виде массива селекторов (['.close-btn'])
 */
export function switchHeaderBtnsVisible($svg, visible, exceptions) {
    let $btns = $svg.find(`.alarm-bell, .share-btn, .export-btn, .archive-btn, .ptz-btn, .maximize-btn, .close-btn`);
    if (Array.isArray(exceptions) && exceptions.length > 0) {
        $btns = $btns.not(exceptions.join(', '));
    }

    if (visible) {
        $btns.removeClass('hidden');
    } else {
        $btns.addClass('hidden');
        $svg.find(`.export-btn, .ptz-btn`).addClass('disabled');
    }
}

export function hideHeaderBtns($svg) {
    $svg.find(`.alarm-bell, .share-btn, .anchor-btn, .export-btn, .archive-btn, .ptz-btn, .maximize-btn`).hide();
}