import {fetchData, log} from "./app";
import {appSession} from "./session";

export let appRights = [];

export function registerRight(r) {
    log(`perms`,'register right', r);
    if (appRights.indexOf(r) < 0) {
        appRights.push(r);
    }
    log(`perms`,'app rights store', appRights);
}

let permissionsCache = {};

export async function checkPermissions(o, a) {
    while (appSession === undefined) {
        await sleepMs(10);
    }

    let of = permissionsCache[o];
    if (of !== undefined) {
        let af = of[a];
        return af !== undefined && af;
    }

    let resp = await fetchData("/user/check_rights", {"subj": appSession.user, "obj": o, "act": a});
    let allow = resp.status && resp.payload

    // кэширование
    let actObj = {};
    actObj[a] = allow;
    permissionsCache[o] = actObj;
    setTimeout(() => {
        delete permissionsCache[o];
    }, 1000);

    return allow;
}

const sleepMs = ms => new Promise(r => setTimeout(r, ms));