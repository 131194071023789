import TreeV from "./treeV";
import {fetchData, log} from "../camera-svg";
import {openWebSocket, subscribeDev, subscribeWsMsg, unSubscribeDev} from "../camera-svg";
import {registerRight} from "../camera-svg/common/permissions";
import {setAuth} from "../camera-svg";

let tree;

$(document).ready(() => {
    // setAuth(undefined);
    openWebSocket();

    // register tree action rights
    registerRight({
        name: 'device_tree',
        description: 'Дерево устройств',
        data: [
            {name: 'add', description: 'Добавить'},
            {name: 'edit', description: 'Изменить'},
            {name: 'del', description: 'Удалить'},
        ]
    });
});

async function initTree(conf = {}, target, devs = undefined) {
    log(`tree`, `init tree`, conf, target,devs);

    if (devs === undefined) {
        devs = (await fetchData('/device/info/all')).payload;
    }

    tree = new TreeV({
        c_target: target,
        onNodeRefresh: (node) => {
            log('refresh', node)
        },
        onNodeEdit: (node) => {
            log('edit', node)
        },
        onNodeRemove: (node) => {
            log('remove from tree', node);
        },
        onDataDrawn: () => {

        },

        c_config: {
            ...conf,
            foldedStatus: false,
            logMode: true,
        },
        c_data: devs
    });

    subscribeWsMsg(msg => {
        switch (msg.type) {
            case 'reconnect':
                subDevs(devs);
                break;

            case 'state':
                tree.updateState(msg.data.devId, msg.data.state);
                break;
        }
    });
    subDevs(devs);

    log("tree", tree);

    return tree;
}

function clearTree() {
    for (const node of tree.data) {
        unSubscribeDev(node.n_item.name);
    }
    tree.clear();
}

function subDevs(devs) {
    devs.forEach(dev => {
        subscribeDev(dev.name);
        if (dev.typeIsContainer) {
            dev.devices.forEach(nDev => {
                subscribeDev(nDev.name);
            })
        }
    })
}


export {initTree, clearTree}