import {fetchData_v2, hideUrlSensData, log, logWarn} from "./common/app";
import {wsStateSub, wsStateUnSub} from "./common/websocket_utils";

export class WebRTCPlayer {
    video = null;
    ws = null;
    promtv = null;
    auth = null;
    camId = null;
    onTimeSyncCb = null;
    cid = null;
    url = null;
    onPlayListeners = [];

    webrtc;
    webrtcSendChannel;
    cc = 0;
    iceServers = [];

    async play(params) {
        this.promtv = params?.promtv || this.promtv;
        this.auth = params?.auth || this.auth;
        this.camId = params?.camId || this.camId;
        this.url = params?.url ? params.url + '/webrtc' : this.url;
        this.urlSafe = hideUrlSensData(this.url);
        this.video = params?.video || this.video;
        this.onTimeSyncCb = params?.onTimeSyncCb;

        // закрываем предыдущее соединение WebRTC
        if (this.webrtc && /*this.reused &&*/ !!this.cid?.length) {
            log(`webrtc`, `close existing webrtc connection, devId:`, this.camId, this.urlSafe);

            this.reset();
        }

        log(`webrtc`, `play with new WebRTC connection`, this.camId, this.urlSafe);

        if (!this.iceServers.length) {
            this.iceServers = (await fetchData_v2(`${this.promtv}/settings/stun`)).servers;
        }

        this.webrtc = new RTCPeerConnection({
            iceServers: [
                {
                    urls: this.iceServers
                }
            ],
            sdpSemantics: 'unified-plan'
        })
        this.webrtcSendChannel = this.createDataChannel();
        log(`webrtc`, `datachannel created`, this.webrtcSendChannel);

        this.webrtc.ontrack = this.onTrack.bind(this);
        this.webrtc.addTransceiver('video', {direction: 'sendrecv'})
        this.webrtc.onnegotiationneeded = this.onNegotiationNeeded.bind(this);
        this.onICEConnectionStateChange = this.onICEConnectionStateChange.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onWsConnectStateChangeHandler = this.onWsConnectStateChangeHandler.bind(this);

        this.subscribeWsState();
    }

    createDataChannel() {
        log(`webrtc`, `create datachannel`);

        const webrtcSendChannel = this.webrtc.createDataChannel(`browser_data_chan_${this.camId}_${this.cc++}`);
        webrtcSendChannel.onopen = (event) => {
            log(`webrtc`, `dataChannel: ${webrtcSendChannel.label} - ${webrtcSendChannel.readyState} has opened`, this.camId);

            webrtcSendChannel.send(JSON.stringify({type: 'ping'}));
        }
        webrtcSendChannel.onclose = (event) => {
            log(`webrtc`, `webrtc dataChannel: ${webrtcSendChannel.label} has closed ${this.camId}`, event);
        }
        webrtcSendChannel.onmessage = (event) => {
            let msg = JSON.parse(event.data);

            switch (msg.type) {
                case 'time':
                    if (typeof this.onTimeSyncCb === 'function') {
                        this.onTimeSyncCb(msg.data);
                    }
                    break;

                case 'cid':
                    log(`webrtc`, `webrtc cid for camera '${this.camId}'`, msg.data);

                    this.cid = msg.data;
                    break;

                default:
                    log(`webrtc`, `devId '${this.camId}' in message`, msg);
            }
        }
        return webrtcSendChannel;
    }

    onTrack(event) {
        log(`webrtc`, event.streams.length + ' track is delivered, camera', this.camId)
        this.video.srcObject = event.streams[0];
        this.video.play();
        this.video.onloadeddata = this.onPlay;

        this.webrtc.oniceconnectionstatechange = this.onICEConnectionStateChange;

        log(`webrtc`, `video started play ${this.promtv}/${this.camId}`);
    }

    onICEConnectionStateChange(e) {
        log(`webrtc`, `ice ${this.webrtc.iceConnectionState}`, this.promtv, this.camId);
        if (this.webrtc.iceConnectionState === 'disconnected' || this.webrtc.iceConnectionState === 'closed' || this.webrtc.iceConnectionState === 'failed') {
            // needReconnection = true; //todo

        } else if (this.webrtc.iceConnectionState === 'connected') {
            // send ready for transmitting
            log(`webrtc`, `wait datachannel state READY`);
            let checkOpen = setInterval(() => {
                if (this.webrtcSendChannel.readyState === 'open') {
                    this.webrtcSendChannel.send(JSON.stringify({type: 'ready'}));
                    log(`webrtc`, `send ready signal`, this.camId);
                    clearInterval(checkOpen);
                }
            }, 20);
        }
    }

    async onNegotiationNeeded() {
        log(`webrtc`, `start negotiation`);

        const offer = await this.webrtc.createOffer(/*{iceRestart:true}*/)
        await this.webrtc.setLocalDescription(offer);

        let u = this.cid ? this.url + "/" + this.cid : this.url;

        fetch(u, {
            method: 'POST',
            body: new URLSearchParams({data: btoa(this.webrtc.localDescription.sdp)})
        })
            .then(response => response.text())
            .then(data => {
                try {
                    this.webrtc.setRemoteDescription(
                        new RTCSessionDescription({type: 'answer', sdp: atob(data)})
                    )
                } catch (e) {
                    logWarn(`webrtc`, 'remote desc set error, reconnecting', {ev: e, data: data});// TODO автоматическое переподключение
                    // needReconnection = true; // todo
                }
            })
            .catch(err => {
                logWarn(`webrtc`, `SDP send error`, this.camId);
                // needReconnection = true; // todo
            })
    }

    reset() {
        log(`webrtc`, this.promtv, this.camId, 'resetting');

        this.webrtcSendChannel.send(JSON.stringify({type: 'close'}));
        this.webrtcSendChannel.close();
        this.webrtc.close();

        wsStateUnSub(this.promtv, this.auth, `webrtc player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);
    }

    destroy() {
        log(`webrtc`, this.promtv, this.camId, 'destroying');

        this.reset();
        this.webrtcSendChannel = null;
        this.webrtc = null;
        this.video.srcObject = null;
        this.video = null;
    }

    onPlay(e) {
        log(`webrtc`, 'onPlay', this.promtv, this.camId);

        this.onPlayListeners.forEach(l => {
            if (typeof l === 'function') {
                l();
            }
        })
    }

    onPlayListener(cb) {
        if (typeof cb === 'function') {
            this.onPlayListeners.push(cb);
        }
    }

    subscribeWsState() {
        wsStateSub(this.promtv, this.auth, `webrtc player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);
    }

    onWsConnectStateChangeHandler(msg) {
        switch (msg.type) {
            case 'reconnect':
                this.play();
                break;

            case 'disconnect':
                break;
        }
    }
}