import './treev.css';
import './promtv.css';
import {log} from "../camera-svg";
import {hasAlarm, hasFault, hasRun} from "../camera-svg";
import {checkPermissions} from "../camera-svg/common/permissions";

export default class TreeV {
    constructor(obj) {
        this.currentId = 0;
        this.target = obj.c_target;
        this.nodeList = {};
        this.onNodeRefresh = obj.onNodeRefresh;
        this.onNodeEdit = obj.onNodeEdit;
        this.onNodeRemove = obj.onNodeRemove;
        this.onDataDrawn = obj.onDataDrawn;
        this.data = obj.c_data;
        this.build(obj.c_config);
    }

    build(c_config) {
        this.config = {
            logMode: false,
            expandedIcon: "ic-caret-down",
            collapsedIcon: "ic-caret-right",
            initCollapsed: false,
            selectable: false,
            displayCommands: true,
        };
        for (let key in this.config) {
            if (c_config[key] !== undefined) {
                this.config[key] = c_config[key];
            }
        }
        $(`#${this.target}`)
            .addClass('ptree')
            .append(`<div id="eq_tree_wrapper"><ul id="eq_tree" class="common-wrapper"></ul></div>`);

        this.log(`transform from`, this.data);
        this.data = this.transformToFlat(this.data);
        this.log(`transform res`, this.data);

        this.sortData(this.data);
        this.log(`sorted data`, this.data);

        this.drawData(this.data);
    }

    log(message, details) {
        if (this.config.logMode) {
            log('tree', message, details);
        }
    }

    getNode(id) {
        return this.nodeList[id];
    }

    setNodeEl(id, el) {
        this.nodeList[id].n_item = el;
        this.updateNode(this.nodeList[id]);
    }

    getChilds(node) {
        let list = [];
        for (let key in this.nodeList) {
            if (node.n_child.includes(this.nodeList[key].id)) {
                list.push(this.nodeList[key]);
            }
        }
        this.log("node childs returned..");
        return list;
    }


    // разворачивание/свёртывание вложенных узлов
    toggleNode(node) {
        if (node.n_child.length > 0) {
            $(`#i_${node.n_html_id}`)
                .removeClass(node.n_collapsed ? this.config.collapsedIcon : this.config.expandedIcon)
                .addClass(node.n_collapsed ? this.config.expandedIcon : this.config.collapsedIcon)

            $(`#c_${node.n_html_id}`)
                .removeClass(node.n_collapsed ? "not-active" : "active")
                .addClass(node.n_collapsed ? "active" : "not-active")

            node.n_collapsed = !node.n_collapsed;
            //change node status
            for (let key in this.nodeList) { // TODO разобраться
                if (this.nodeList[key].n_id === node.n_id) {
                    this.nodeList[key].collapsed = node.collapsed;
                }
            }
        }
    }


    deleteNode(node) {
        node.n_child.forEach(childNode => {
            this.deleteNode(childNode);
        });

        let delInd = this.data.indexOf(node)
        if (delInd >= 0) {
            this.data.splice(delInd, 1);
        }
        this.log("node removed from, new data", this.data);

        delete this.nodeList[node.n_item.name];

        $(`#${node.n_html_id}`).remove();
        this.log("node removed..(" + node.n_id + ")");
    }

    createNode(data) {
        this.log(`create node`, data);

        let newNode = this.transformToFlat(data)

        this.log(`create node, transformed data`, newNode);

        this.data.push(newNode[0]);
        this.sortData(this.data);
        $(`#eq_tree`).children().remove();
        this.drawData(this.data);

        this.log("Node is created", newNode);
    }

    updateNode(node) {
        //first remove old node
        //console.log(this.getNode(node.id.split('_')[1]))
        // this.deleteNode(node);
        this.nodeList[node.n_item.name] = node;
        //clear old parent's childs if old parent info is exist
        /*if (node.old_parent !== undefined && node.old_parent.id !== 0) {
            this.nodeList[node.old_parent.value].childs = this.nodeList[node.old_parent.value].childs.filter((x) => {
                return x !== node.id;
            });
            //if child count is 0 then remove minus icon
            if (this.nodeList[node.old_parent.value].childs.length === 0) {
                document.getElementById("i_" + node.old_parent.id).style.display = "none";
            }
        }*/
        //draw new node with childs
        const set = (data) => {
            this.drawNode(data, true);
            /*let childs = this.getChilds(data);
            if (childs.length > 0) {
                for (let i = 0; i < childs.length; i++) {
                    set(childs[i]);
                }
            }*/
        };

        set(node);

        //log
        this.log("Node is created (" + node.n_id + ")");
        //return node
        return node;
    }

    drawNode(node, replace = false) {
        this.log(`${replace ? 'replace' : 'draw'} node`, node);

        let $self = $(`<div></div>`)
            .attr('id', "div_g_" + node.n_html_id)
            .addClass('item-wrapper');

        if (this.config.selectable) {
            $self.append($(`<div></div>`).addClass(`select-icon-wrapper form-check form-switch`))
        }

        $self.append($(`<div></div>`).addClass(`type-icon-wrapper`))
            .append($(`<div></div>`).addClass(`expand-icon-wrapper`))
            .append($(`<div></div>`).addClass(`title-wrapper`))
            .append($(`<div></div>`).addClass(`ip-wrapper`))
            .append($(`<div></div>`).addClass(`info-wrapper`))
            .append($(`<div></div>`).addClass(`groups-wrapper`));

        if (this.config.displayCommands) {
            $self.append($(`<div></div>`).addClass(`cmn-cmd-wrapper`));
        }

        let $item = $(`<li></li>`)
            .attr('id', node.n_html_id)
            .append($self)
            .append(
                $(`<ul></ul>`)
                    .attr("id", "c_" + node.n_html_id)
                    .addClass(this.config.initCollapsed ? "not-active" : "active")
                    .addClass("group-wrapper"));

        //display checkBox for select item
        if (this.config.selectable) {
            $(`<input class="form-check-input" type="checkbox" role="switch" name="${node.n_item.name}">`)
                .on('click', (e) => {
                    if (typeof node.n_onToggle === 'function') {
                        node.n_onToggle(e.target.checked);
                    }
                })
                .appendTo($item.find(`.select-icon-wrapper`));
        }

        //display type icons
        $(`<a></a>`)
            .attr("id", "a_dr_" + node.n_html_id)
            .attr("href", "javascript:;")
            .append($(`<i></i>`).addClass(node.n_item.icon))
            .appendTo($item.find(`.type-icon-wrapper`))

        //display collapse/expand switch
        $item.find(`.expand-icon-wrapper`)
            .css(`width`, node.n_item.typeIsContainer || !node.n_parent ? '32px' : '2px')
            .css(`min-width`, node.n_item.typeIsContainer || !node.n_parent ? '32px' : '2px')
            .append(
                //set a group/ungroup switch
                $(`<a></a>`)
                    .attr("id", `a_toggle_${node.n_html_id}`)
                    .attr("href", "javascript:;")
                    .append($(`<i></i>`)
                        .attr("id", "i_" + node.n_html_id)
                        .addClass(this.config.initCollapsed ? this.config.collapsedIcon : this.config.expandedIcon)
                        .addClass("mr-3")
                        .css('display', node.n_item.typeIsContainer ? '' : 'none')
                    )
                    .click((e) => this.toggleNode(node))
            )

        //display node title
        $(`<a></a>`)
            .attr("id", `a_title_${node.n_html_id}`)
            .append(node.n_item.displayName)
            .appendTo($item.find(`.title-wrapper`))

        //display ip
        $(`<a></a>`)
            .attr("id", `a_ip_${node.n_html_id}`)
            .append(node.n_item.ip)
            .appendTo($item.find(`.ip-wrapper`))

        //display detectors
        let detInfo = "";
        let detectors = node.n_item.detectors || [];
        detectors.forEach(det => {
            detInfo += `<a class="detector-tooltip det-${det.isDetectorActivated ? 'enable' : 'disable'}">${det.detectorName}</a><br>`
        });

        $("<i></i>")
            .addClass("ic-brain-circuit")
            .addClass(detectors.length ? "" : 'func-absent')
            .attr('data-bs-toggle', detectors.length ? "tooltip" : "")
            .attr('data-bs-html', "true")
            .attr('data-bs-custom-class', "custom-tooltip")
            .attr('data-bs-title', detInfo)
            .addClass('mr-2')
            .appendTo($item.find(`.info-wrapper`))
        //display ptz support
        $("<i></i>")
            .addClass("ic-arrows-up-down-left-right")
            .addClass(node.n_item.ptzIsExists ? (node.n_item.ptzIsActive === true ? '' : 'func-disabled') : 'func-absent')
            .addClass('mr-2')
            .appendTo($item.find(`.info-wrapper`))
        //display archive support
        $("<i></i>")
            .addClass("ic-clock-rotate-left")
            .addClass(node.n_item.archives != null && Object.keys(node.n_item.archives).length > 0 ? '' : 'func-absent')
            .addClass('mr-2')
            .appendTo($item.find(`.info-wrapper`))
        //display stream play symbol
        $("<i></i>")
            .addClass("ml-3")
            .addClass("fas")
            .addClass("fa-play")
            .addClass(node.n_item.typeIsContainer ? 'func-absent' : "conn-state")
            .addClass(node.n_item.connState === 'online' ? 'online' : 'idle')
            .addClass('mr-2')
            .appendTo($item.find(`.info-wrapper`))

        //display device group names
        let groupInfo = node.n_item.groupNames.length ? "" : `<a class="groups-tooltip">Нет групп</a><br>`;
        for (const grName of node.n_item.groupNames){
            groupInfo += `<a class="groups-tooltip">${grName}</a><br>`
        }
        $item.find(`.groups-wrapper`)
            .text(`Групп устройств - (${node.n_item.groupNames.length})`)
            .attr('data-bs-toggle', node.n_item.groupNames ? "tooltip" : "")
            .attr('data-bs-html', "true")
            .attr('data-bs-custom-class', "custom-tooltip")
            .attr('data-bs-title', groupInfo)
            .each(function () {
                if (replace) {
                    new bootstrap.Tooltip(this);
                }
            });

        //common commands
        if (this.config.displayCommands) {
            //refresh
            /*$("<i></i>")
                .addClass("ic-arrows-rotate")
                .addClass('mr-2')
                .addClass('func-disabled')
                .click(() => this.onNodeRefresh(node))
                .appendTo($item.find(`.cmn-cmd-wrapper`))*/
            //edit
            let $edit = $("<i></i>")
                .addClass("fas fa-circle-info")
                .addClass('mr-2')
                // .addClass('func-disabled')
                .click(() => this.onNodeEdit(node))
                .appendTo($item.find(`.cmn-cmd-wrapper`))
            //delete
            let $del = $("<i></i>")
                .addClass("ic-xmark")
                .addClass('mr-2')
                .click(() => this.onNodeRemove(node))
                .appendTo($item.find(`.cmn-cmd-wrapper`))

            checkPermissions("device_tree", "del").then(allowed => {
                this.log('allow deleting:', allowed);
                $del.addClass(!allowed ? 'func-absent' : '');
            });
        }

        if (replace) {
            $(`#${"div_g_" + node.n_html_id}`).replaceWith($self);
        } else {
            if (!node.n_parent) {
                $(`#eq_tree`).append($item);
            } else {
                $(`#c_${node.n_parent.n_html_id}`).append($item);
            }
        }

        this.updateState(node.n_item.name, node.n_item.stateFlags);
    }


    transformToFlat(data, parent = undefined, flatDevTree = []) {
        if (!Array.isArray(data)) {
            data = [data]
        }
        data.forEach(dev => {
            let dev_node = {
                n_id: ++this.currentId,
                n_parent: parent,
                n_child: [],
                n_item: dev,
                n_collapsed: this.config.initCollapsed,
                n_html_id: "equip" + this.currentId,
            };
            flatDevTree.push(dev_node);
            this.nodeList[dev.name] = dev_node;

            if (parent) {
                parent.n_child.push(dev_node);
            }

            if (dev["typeIsContainer"]) {
                this.transformToFlat(dev.devices, dev_node, flatDevTree);
            }
        })

        return flatDevTree
    }

    sortData(data) {
        data.sort((a, b) => a.n_item.displayName.localeCompare(b.n_item.displayName));
        data.forEach(node => {
            if (node.n_child.length > 0) {
                this.sortData(node.n_child);
            }
        });
    }

    drawData(data, onlyParent = true) {
        this.log(`draw data`, data);

        data.forEach(node => {
            if (!node.n_parent && onlyParent || node.n_parent && !onlyParent) {
                this.drawNode(node);

                if (node.n_child.length > 0) {
                    this.drawData(node.n_child, false)
                }
            }
        });

        if (this.onDataDrawn) {
            //init dev detectors tooltip (bootstrap)
            $('[data-bs-toggle="tooltip"]').each(function () {
                new bootstrap.Tooltip(this)
            })

            this.onDataDrawn();
        }
    }

    updateState(devId, state) {
        let node = this.nodeList[devId];
        node.n_item.stateFlags = state;

        //рамка
        $(`#${node.n_html_id}`).find(`.item-wrapper`)
            .removeClass('item-fault')
            .removeClass('item-alarm')
            .addClass(hasFault(state) ? 'item-fault' : hasAlarm(state) ? 'item-alarm' : '');


        //значки потоков
        $(`#${node.n_html_id} .conn-state`)
            .removeClass('idle')
            .removeClass('online')
            .addClass(hasRun(state) ? 'online' : 'idle');
    }


    // простановка галочки напротив выбранных устройств
    selectItems(itemsObj) {
        itemsObj = itemsObj || {};

        this.log(`select`, itemsObj);

        for (const node of this.data) {
            node.n_onToggle = (checked) => {
                for (const child of node.n_child) {
                    this.setNodeSelected(child, checked);
                }

                if (checked && node.n_parent) {
                    this.setNodeSelected(node.n_parent, checked);
                }
            }

            this.setNodeSelected(node, node.n_item.name in itemsObj);
        }
    }

    getNodeElJq(node) {
        return $(`#div_g_${node.n_html_id}`);
    }

    setNodeSelected(node, val = true) {
        this.getNodeElJq(node).find(`input[type=checkbox]`).prop('checked', val);
    }

    isNodeSelected(node) {
        return this.getNodeElJq(node).find(`input[type=checkbox]`).prop('checked');
    }

    addNodeSelectListener(node, cb) {
        this.getNodeElJq(node).find(`input[type=checkbox]`).on('click', e => {

        })
    }

    getSelectedNodesObj() {
        let selNodesObj = {};
        for (const node of this.data) {
            if (this.isNodeSelected(node)) {
                selNodesObj[node.n_item.name] = true;
            }
        }
        return selNodesObj;
    }

    clear() {
        this.log(`clear tree`);
        $(`#${this.target}`).empty();
        this.data = [];
        this.nodeList = {};
    }
}
