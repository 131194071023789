import {fetchData_v2, log} from "./common/app";
import {resetBusy, setOtherBusy} from "./cameraHeader";
import {CamScrFAwBtn} from "./components/camScrButton";
import {Dropdown} from "./components/dropdown";
import {Alert} from "./components/alert";
import {Dialog} from "./components/dialog";
import {sendMsgExt} from "./common/websocket_utils";

export class Ptz {
    $svg;
    promtv;
    auth;
    camId;
    session;
    pos;
    posDef;
    ptzDeadZone = 40;
    presets = {};
    switchListener;

    constructor($svg, promtv, auth, camId, session, posDef, presets) {
        this.$svg = $svg;
        this.promtv = promtv;
        this.auth = auth;
        this.camId = camId;
        this.session = session;
        this.posDef = posDef;
        this.presets = presets || {};
    }

    occupationHandler(msg) {
        if (this.session.cid !== msg.data.wsCid) { //todo проверить
            if (msg.data.isOccupied && this.session.priority <= msg.data.prio) {
                this.block(msg.data.user);
            } else if (msg.data.isOccupied && this.session.priority > msg.data.prio) {
                this.informPtzUsed();
            } else if (!msg.data.isOccupied) {
                this.unblock(this.camId);
            }
        } else if (!msg.data.isOccupied) {
            let mode = this.$svg.attr(`data-mode`);
            if (mode.includes('ptz')) {
                this.switchPtzOff();

                new Alert({
                    parent: this.$svg.parent()[0],
                    text: `Был осуществлён выход из режима PTZ из-за длительного бездействия`
                }).display();
            }
        }
    }

    askUserHandler(msg) {
        log('camera', this.promtv, this.camId, 'ask user', msg.data);

        this.$svg.parent().find(`.camera-dialog`).remove();
        new Dialog({
            parent: this.$svg.parent()[0],
            text: msg.data.eventStatus === 'new' ?
                `Произошло событие: ${msg.data.eventText}. Желаете повернуть камеру на его источник?` :
                msg.data.eventStatus === 'changed' ? 'Сменился источник приоритетного события, желаете повернуть камеру на него?' :
                    `Все источники событий камеры вернулись в исходное состояние. Желаете вернуть камеру в исходное положение?`,
            ApplyText: 'Повернуть',
            CancelText: 'Не нужно',
            onApply: async () => {
                this.moveToPosition(msg.data.ptzPos);
                sendMsgExt(this.promtv, this.camId, {
                    type: 'ptz_hide_dialog',
                    data: this.camId,
                });
            }
        }).display();
    }

    hideDialogHandler(msg) {
        log(`camera`, this.promtv, this.camId, `hide ptz move action dialog by remote`);
        this.$svg.parent().find(`.camera-dialog`).remove();
    }

    block(user) {
        log(`ptz`, this.promtv, this.camId, `blocking ptz for camera`);

        this.$svg.find('.ptz-btn').addClass('blocked').removeClass('used');

        let mode = this.$svg.attr(`data-mode`);
        if (mode.includes('ptz')) {
            this.switch();

            new Alert({
                parent: this.$svg.parent()[0],
                text: `Управление PTZ было перехвачено пользователем с более высоким приоритетом (${user})`
            }).display();
        }
    }

    unblock() {
        log(`ptz`, `unblocking ptz for camera`, this.camId);

        this.$svg.find('.ptz-btn').removeClass('blocked').removeClass('used');
        this.$svg.parent().find('.widget-alert-wr').remove();
    } //

    informPtzUsed() {
        this.$svg.find('.ptz-btn').addClass('used').removeClass('blocked');
    }

    async switch(ptzCb) {
        log(`ptz`, `switch ptz: ${this.promtv}/${this.camId}, ptz callback:`, typeof ptzCb === 'function')

        let mode = this.$svg.attr(`data-mode`);
        let $ptzControl = this.$svg.find('.ptz-control');
        let $area = this.$svg.find(`.ptz-area`);

        if (mode.length === 0) {
            this.$svg.find(`.ptz-btn`).addClass('active').removeClass('used');
            let resp = await this.sendAcquireRequest();
            if (!resp.status) {
                // display message
                this.$svg.find(`.ptz-btn`).removeClass('active');

                this.$svg.parent().find('.widget-alert-wr').remove();

                let text = resp.error === 'camera busy' ?
                    `Управление PTZ осуществляется пользователем (${resp.payload.user})` :
                    `Произошла ошибка: ${resp.error}`;

                new Alert({
                    parent: this.$svg.parent()[0],
                    text: text,
                }).display();

                log(`ptz`, 'cant acquire ptz control', this.promtv, this.camId, resp.error);

                return;
            }

            log(`ptz`, `switch ptz on`, this.promtv, this.camId);

            this.$svg.attr(`data-mode`, 'ptz');
            this.$svg.find(`.ptz-btn`).addClass('active');
            setOtherBusy(this.$svg, this.$svg.find(`.ptz-btn`)[0]);

            let ind = document.createElementNS("http://www.w3.org/2000/svg", "path");
            $(ind)
                .attr('transform', 'translate(281 150)')
                .attr('d', 'M19.7071 0.292893C19.3166 -0.0976311 18.6834 -0.0976311 18.2929 0.292893L11.9289 6.65685C11.5384 7.04738 11.5384 7.68054 11.9289 8.07107C12.3195 8.46159 12.9526 8.46159 13.3431 8.07107L18 3.41421V18H3.41421L8.07107 13.3431C8.46159 12.9526 8.46159 12.3195 8.07107 11.9289C7.68054 11.5384 7.04738 11.5384 6.65685 11.9289L0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071L6.65685 26.0711C7.04738 26.4616 7.68054 26.4616 8.07107 26.0711C8.46159 25.6805 8.46159 25.0474 8.07107 24.6569L3.41421 20H18V34.5858L13.3431 29.9289C12.9526 29.5384 12.3195 29.5384 11.9289 29.9289C11.5384 30.3195 11.5384 30.9526 11.9289 31.3431L18.2929 37.7071C18.6834 38.0976 19.3166 38.0976 19.7071 37.7071L26.0711 31.3431C26.4616 30.9526 26.4616 30.3195 26.0711 29.9289C25.6805 29.5384 25.0474 29.5384 24.6569 29.9289L20 34.5858V20H34.5858L29.9289 24.6569C29.5384 25.0474 29.5384 25.6805 29.9289 26.0711C30.3195 26.4616 30.9526 26.4616 31.3431 26.0711L37.7071 19.7071C38.0976 19.3166 38.0976 18.6834 37.7071 18.2929L31.3431 11.9289C30.9526 11.5384 30.3195 11.5384 29.9289 11.9289C29.5384 12.3195 29.5384 12.9526 29.9289 13.3431L34.5858 18H20V3.41421L24.6569 8.07107C25.0474 8.46159 25.6805 8.46159 26.0711 8.07107C26.4616 7.68054 26.4616 7.04738 26.0711 6.65685L19.7071 0.292893Z')
                .appendTo($ptzControl);

            let zoomInBtn = new CamScrFAwBtn({
                defClasses: "wic zoom-in-btn",
                x: 292,
                y: 130,
                parent: $ptzControl[0],
                action: () => this.changeZoom(0.06),
            }).display();
            let zoomOutBtn = new CamScrFAwBtn({
                defClasses: "wic zoom-out-btn",
                x: 292,
                y: 194,
                parent: $ptzControl[0],
                action: () => this.changeZoom(-0.06)
            }).display();
            let presetBtn = new CamScrFAwBtn({
                defClasses: "wic preset-btn",
                x: 576,
                y: 8,
                parent: $ptzControl[0],
            }).display();
            new CamScrFAwBtn({
                defClasses: "wic ptz-home-btn",
                x: 556,
                y: 8,
                parent: $ptzControl[0],
                action: () => {
                    if (!this.posDef) {
                        return;
                    }

                    this.moveToPosition(this.posDef);
                }
            }).display();
            if (typeof ptzCb === 'function') {
                new CamScrFAwBtn({
                    defClasses: "wic ptz-home-save-btn",
                    x: 536,
                    y: 8,
                    parent: $ptzControl[0],
                    action: () => ptzCb(this.pos),
                }).display();
            }

            $area
                .css({'cursor': 'crosshair'})
                .click(e => {
                    let svg = this.$svg[0];
                    let pt = svg.createSVGPoint();
                    pt.x = e.clientX;
                    pt.y = e.clientY;
                    let point = pt.matrixTransform(svg.getScreenCTM().inverse());

                    let posX = point.x;
                    let posY = point.y - 32;
                    // let zoom = cache[camId].ptz.zoom;

                    let dPan = (posX - 300) / 5300 /*(3600 + zoom * 50000)*/;//zoom 1.0=75000, zoom 0.0=3600
                    let dTilt = (posY - 169) / 2600 /*(1800 + zoom * 20000)*/;//zoom 1.0=37000, zoom 0.0=1800

                    log(`ptz`, `ptz click`, {dX: dPan.toFixed(4), dY: dTilt.toFixed(4)});

                    if (Math.abs(dPan) + Math.abs(dTilt) < 0.0001) {
                        log(`ptz`, `ignored ptz move`, {dPan: dPan, dTilt: dTilt});
                        return;
                    }
                    this.moveCamera(dPan, dTilt);
                });

            let presList = [];
            let _this = this;
            for (const [id, name] of Object.entries(this.presets)) {
                presList.push({
                    name: name, //todo проверить контекст анонимной функции
                    action: () => fetchData_v2(`${_this.promtv}/device/ptz/preset/${_this.camId}/${id}`, _this.auth),
                });
            }

            log(`ptz`, this.promtv, this.camId, `presetsList`, presList);

            let presetDropdown = new Dropdown({
                initiator: presetBtn.el,
                items: presList,
                appearance: 'ld',
                freeze: true,
            });

            if (typeof this.switchListener === 'function') {
                this.switchListener(true);
            }

        } else if (mode.includes('ptz')) {
            log(`ptz`, this.promtv, this.camId, `switch ptz off`);

            this.switchPtzOff();
        }
    }

    checkAndSwitchPtzOff() {
        log(`ptz`, this.promtv, this.camId, `try to switch ptz off`);

        let mode = this.$svg.attr(`data-mode`);
        if (mode.includes('ptz')) {
            this.switchPtzOff();
        }
    }

    switchPtzOff() {
        let $ptzControl = this.$svg.find('.ptz-control');
        let $area = this.$svg.find(`.ptz-area`);

        this.$svg.attr(`data-mode`, '');
        this.$svg.find(`.ptz-btn`).removeClass('active');
        resetBusy(this.$svg);

        $ptzControl.empty();
        this.$svg.find(`.my-dropdown`).remove();
        $area.unbind('click')
        $area.removeAttr('style');

        this.releasePtz();

        if (typeof this.switchListener === 'function') {
            this.switchListener(false);
        }
    }

    async sendAcquireRequest() {
        let resp = await fetchData_v2(`${this.promtv}/device/ptz/acquire/${this.camId}/${this.session.cid}`, this.auth);
        if (resp.status) {
            this.pos = resp.payload;
        }

        log(`ptz`, `acquire ptz control for ${this.promtv}/${this.camId}, res =`, resp);

        return resp;
    }

    releasePtz() {
        log(`ptz`, this.promtv, this.camId, `release ptz for`);

        fetchData_v2(`${this.promtv}/device/ptz/release/${this.camId}/${this.session.cid}`, this.auth);
    }

    moveCamera(dpan, dtilt) {
        fetchData_v2(`${this.promtv}/device/ptz/move/${this.camId}/${this.session.cid}?dpan=${dpan.toFixed(4)}&dtilt=${dtilt.toFixed(4)}`, this.auth)
            .then(r => {
                if (r.status) {
                    this.pos = r.payload;
                }
            });
    }

    changeZoom(dZoom) {
        fetchData_v2(`${this.promtv}/device/ptz/zoom/${this.camId}/${this.session.cid}?dZoom=${dZoom}`, this.auth)
            .then(r => {
                if (r.status) {
                    this.pos = r.payload;
                }
            });
    }

    moveToPosition(pos) {
        log(`ptz`, this.promtv, this.camId, `move to position`);

        fetchData_v2(`${this.promtv}/device/ptz/position/${this.camId}/${this.session.cid}`, this.auth, pos).then(r => {
            if (r.status) {
                this.pos = r.payload;
            }
        });
    } //

    setDefaultPosition(pos) {
        this.posDef = pos;

        log(`ptz`, this.promtv, this.camId, `save def ptz position`, pos);
    }

    close() {
        this.checkAndSwitchPtzOff();
        this.$svg = null;
    }

    /**
     *
     * @param {function(boolean)} cb
     */
    onSwitchListener(cb) {
        this.switchListener = cb;
    }

}

