import {fetchData, log, logError, serverWs} from "./common/app";
import {cache} from "./archive";
import {stopLoading} from "./camera";
import {subscribeWsMsg, unsubscribeWsMsg} from "./common/websocket";

class MSEPlayer {
    video = null;
    ws = null;
    sound = false;
    mseSourceBuffer = null;
    mse = null;
    mseQueue = [];
    mseStreamingStarted = false;
    camId = null;
    channel = 0;
    checkTime = null;
    checkTimeCounter = 0;
    onTimeSyncCb = null;
    cid = null;
    url = null;
    packetTimeout = null;
    onReconnect = null;
    inited = false;
    errorCount = 0;
    onDisconnected = null;

    constructor(conf) {
        this.camId = conf.camId;
        this.channel = conf.channel || 0;
        this.url = conf.url;
        this.video = conf.video;
        this.onTimeSyncCb = conf.onTimeSyncCb || function (time) {
        }
    }

    playMse() {
        log(`mse`, `=== use new`, this.url, this.video);

        let _this = this;

        this.mse = new MediaSource();
        this.video.src = window.URL.createObjectURL(this.mse);
        this.video.onloadeddata = (event) => this.onPlay();

        this.mse.addEventListener('sourceopen', this.onMseOpen.bind(this));
        this.mse.addEventListener('sourceended', () => log(`mse`, 'sourceended', _this.camId));
        this.mse.addEventListener('sourceclose', () => log(`mse`, `sourceclose`, _this.camId));
        this.mse.addEventListener('error', () => log(`mse`, 'error', _this.camId));
        this.mse.addEventListener('abort', () => log(`mes`, 'abort', _this.camId));
        this.mse.addEventListener('updatestart', () => log(`mse`, 'updatestart', _this.camId));
        this.mse.addEventListener('update', () => log(`mse`, 'update', _this.camId));
        this.mse.addEventListener('updateend', () => log(`mse`, 'updateend', _this.camId));
        this.mse.addEventListener('addsourcebuffer', () => log(`mse`, 'addsourcebuffer', _this.camId));
        this.mse.addEventListener('removesourcebuffer', () => log(`mse`, 'removesourcebuffer', _this.camId));
    }

    onMseOpen() {
        log(`mse`, `sourceopen`, this.camId);

        let ws_url = serverWs + this.url;

        log(`mse`, `connecting websocket`, this.camId, ws_url);

        this.ws = new WebSocket(ws_url);
        this.ws.binaryType = "arraybuffer";

        this.ws.onopen = (e) => {
            log(`mse`, `websocket: connected`, this.camId);
            this.ws.send(JSON.stringify({type: 'info', data: 'mse client widget ws connected'}));
            this.setPacketTimeout();
        }
        this.ws.onclose = (e) => log(`mse`, `websocket: closed`, this.camId);
        this.ws.onerror = (e) => log(`mse`, 'websocket: error', this.camId);
        this.ws.onmessage = this.wsMsgHandle.bind(this);
    }

    wsMsgHandle(event) {
        // log(`mse`, `message`, event.data);

        // this.checkStalled();// ложняки если не video за пределами видимой области

        let data = new Uint8Array(event.data);

        if (data[3] === 24) {
            this.inited = true;
            log(`mse`, 'data: init_file', this.camId);
        }

        if (data[0] === 9) {
            let decoded_arr = data.slice(1);
            let mimeCodec;
            if (window.TextDecoder) {
                mimeCodec = new TextDecoder("utf-8").decode(decoded_arr);
            } else {
                mimeCodec = Utf8ArrayToStr(decoded_arr);
            }
            if (mimeCodec.indexOf(',') > 0) {
                this.sound = true;
            }
            log(`mse`, this.camId, 'codec: ' + mimeCodec);

            try {
                this.mseSourceBuffer = this.mse.addSourceBuffer('video/mp4; codecs="' + mimeCodec + '"');
                this.mseSourceBuffer.mode = "sequence";
                this.mseSourceBuffer.addEventListener("updateend", this.pushPacket.bind(this));

                this.setPacketTimeout();

            } catch (e) {
                log(`mse`, `add source error`, this.camId, e);

            }

        } else if (data[0] === 0 && this.mseSourceBuffer != null && this.inited) {
            this.readPacket(event.data);

        } else if (data.length === 0) {
            let msg = JSON.parse(event.data);
            switch (msg.type) {
                case 'time':
                    if (typeof this.onTimeSyncCb === 'function') {
                        this.onTimeSyncCb(msg.data);
                    }
                    break;

                case 'cid':
                    log(`mse`, `cid received for`, this.camId, msg.data);
                    this.cid = msg.data;
                    break;

                case 'error':
                    logError(`mse`, `error`, this.camId, msg.data);
                    break;

                default:
                    log(`mse`, `unknown message`);
            }
        }
    }

    readPacket(packet) {
        this.setPacketTimeout();

        this.correctPlayTime();

        if (!this.mseStreamingStarted) {
            try {
                this.mseSourceBuffer.appendBuffer(packet);
                this.mseStreamingStarted = true;
                this.errorCount = 0;

            } catch (e) {
                let bufInd = this.video.buffered.length - 1;
                let info
                if (bufInd >= 0) {
                    info = `${this.video.buffered.start(bufInd)} > ${this.video.currentTime} > ${this.video.buffered.end(bufInd)}`;
                    info += `,  ${this.video.buffered.end(bufInd) - this.video.currentTime}`;
                }

                log(`mse`, 'readPacket error', this.camId, info ? info : '', e, this.video.error);

                if (!this.onTimeSyncCb) {
                    this.errorCount++;
                    if (this.errorCount > 50) {
                        this.ws.send(JSON.stringify({
                            type: 'error',
                            data: '>50 append buffer errors, reconnecting...'
                        }));
                        startPlayMse(this.camId, this.url, this.onTimeSyncCb);
                    }

                    log(`mse`, `read packet error (${this.errorCount})`, e);
                }
            }

            return;
        }

        this.mseQueue.push(packet);

        if (!this.mseSourceBuffer.updating) {
            this.pushPacket();
        }
    }

    setPacketTimeout() {
        this.stopPacketTimeout(false);

        this.packetTimeout = setTimeout(() => {
            log(`mse`, `packet timeout ${this.camId}, reconnecting...`);
            if (this.ws.readyState === 1) {
                this.ws.send(JSON.stringify({type: 'error', data: 'packet timeout, reconnecting...'}));
            }
            startPlayMse(this.camId, this.url, this.onTimeSyncCb);
        }, 20_000);
    }

    pushPacket() {
        if (!this.mseSourceBuffer.updating) {
            if (this.mseQueue.length > 0) {
                let packet = this.mseQueue.shift();

                try {
                    this.mseSourceBuffer.appendBuffer(packet)

                } catch (e) {
                    log(`mse`, `push packet error`, this.camId, e);

                    this.errorCount++;
                    if (this.errorCount > 50) {
                        this.ws.send(JSON.stringify({
                            type: 'error',
                            data: '>50 append buffer errors, reconnecting...'
                        }));
                        startPlayMse(this.camId, this.url, this.onTimeSyncCb);
                    }

                    log(`mse`, `push packet error (${this.errorCount})`, e);
                }

            } else {
                this.mseStreamingStarted = false;
            }
        }

        this.correctPlayTime();
    }

    correctPlayTime() {
        if (this.video.buffered.length > 0) {
            let bufTime = this.video.buffered.end(this.video.buffered.length - 1);

            if (typeof document.hidden !== "undefined" && document.hidden) {
                if (!this.sound) {
                    this.video.currentTime = bufTime - 0.5;
                }
            } else { // Math.abs() - на случай зависания видео, когда video.currentTime >> video.buffered.end
                if (Math.abs(bufTime - this.video.currentTime) > 1) {
                    this.video.currentTime = bufTime - 0.5;
                }
            }
        }
    }

    checkStalled() {
        if (!!this.video.currentTime) {
            if (this.video.currentTime === this.checktime) {
                this.checktimecounter += 1;
            } else {
                this.checktimecounter = 0;
            }
        }
        if (this.checktimecounter > 10) {
            log(`mse`, 'player not move', this.camId, this.video.currentTime, this.checktime);
            // _playLive(uuid, videoPlayerVar.index(), channel, 'mse');//todo
        }
        this.checktime = this.video.currentTime;
    }

    destroy() {
        log(`mse`, 'readyState', this.mse.readyState);

        if (!!this.packetTimeout) {
            this.stopPacketTimeout(true);
            log(`mse`, 'clear packet timeout (destroy)', this.camId);
        }
        if (this.ws != null) {
            this.ws.onclose = null;
            this.ws.close(1000, "stop streaming");
        }
        unsubscribeWsMsg(this.onReconnect);
        unsubscribeWsMsg(this.onDisconnected);

        // чтобы видео не зависало при показе и скрытии виджетов
        this.video.pause();
        this.video.onloadeddata = null;
        this.video.src = '';

        clearMseCache(this.camId);

        log(`mse`, 'Event: PlayerDestroy', this.camId, "media state", this.mse.readyState);
    }

    onPlay() {
        if (!(this.camId in cache)) {
            return;
        }

        stopLoading($(cache[this.camId].svg));

        if (Array.isArray(cache[this.camId].videoReadyListeners === 'array')) {
            cache[this.camId].videoReadyListeners.forEach(l => {
                if (typeof l === 'function') {
                    l();
                }
            })
        }
    }

    stopPacketTimeout(logged) {
        clearTimeout(this.packetTimeout);
        if (logged) {
            log(`mse`, 'clear packet timeout', this.camId);
        }
    }
}

function Utf8ArrayToStr(array) {
    let out, i, len, c;
    let char2, char3;
    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 7:
                out += String.fromCharCode(c);
                break;
            case 13:
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
        }
    }
    return out;
}

function startPlayMseNew(camId, url, cbArchivePlay) {
    if (!(camId in cache)) {
        log(`mse`, `can't start new player, no camera in cache`, camId);
        return;
    }

    log(`mse`, `create new player`, camId, url);

    let video = cache[camId].svg.getElementsByTagName(`video`)[0];
    let player = new MSEPlayer({camId: camId, url: url, video: video});
    player.onTimeSyncCb = cbArchivePlay;
    player.onReconnect = (msg) => {
        if (msg.type === 'reconnect') {
            player.destroy();
            startPlayMse(camId, url, cbArchivePlay);
        }
    }
    player.onDisconnected  = (msg) => {
        if (msg.type === 'disconnect') {
            player.stopPacketTimeout(true);
        }
    }

    cache[camId].mse = player;
    subscribeWsMsg(player.onReconnect, `mse player ${player.camId} (reconnect)`);
    subscribeWsMsg(player.onDisconnected, `mse player ${player.camId} (disconnect)`);

    player.playMse();
}

export function startPlayMse(camId, url, cbArchivePlay) {
    let exMse = cache[camId]?.mse;

    if (!exMse?.cid) {
        stopMse(camId);
        return startPlayMseNew(camId, url, cbArchivePlay);
    }

    log(`mse`, 'play with existing player', camId, url, exMse);

    exMse.onTimeSyncCb = cbArchivePlay;
    exMse.inited = false;
    exMse.errorCount = 0;

    fetchData(`${url}/${exMse.cid}`);
}

export function stopMse(camId) {
    cache[camId]?.mse?.destroy();
}

function clearMseCache(camId) {
    delete cache[camId]?.mse;
}