import "../../static/font/widget-icon-font.css";
import fontWoff from "../../static/font/widgetIcons.woff";

// встраивание шрифта по умолчанию widgetIcons
let newStyle = document.createElement("style");
newStyle.appendChild(document.createTextNode(`
@font-face {
  font-family: 'widgetIcons';
  src: url(${fontWoff}) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
`));
document.head.appendChild(newStyle);

export function widgetSvg() {
    return `
<svg class="camera loading" viewBox="0 0 600 370" preserveAspectRatio="none" fill="none" data-mode=""
     xmlns="http://www.w3.org/2000/svg">

    <!--    header -->
    <foreignObject x="0" y="0" width="600" height="33">
        <div class="header"> 
            <div class="title">
                <text class="camera-caption"></text>
            </div>            
            
            <div class="status">
                <text class="wic alarm-bell"></text>
                <text class="wic motion-icon"></text>
            </div>
            
            <div class="control">                
                <text class="wic anchor-btn"></text>
                <text class="wic share-btn"></text>
                <text class="wic export-btn"></text>
                <text class="wic archive-btn r-btn"></text>
                <text class="wic ptz-btn r-btn"></text>
                <text class="wic maximize-btn"></text>
                <text class="wic close-btn"></text>
            </div>            
        </div>
    </foreignObject>
        
    <!--    video area -->
    <g class="video-area" transform="translate(0 32)">
        <foreignObject class="video-wrapper" x="0" y="0" width="600" height="338">
            <video autoplay muted playsinline></video>
        </foreignObject>
        <path class="ptz-area" d="M0,0 h600 v328 a10,10 0 0 1 -10,10 h-580 a10,10 0 0 1 -10,-10 v-328" fill="white" fill-opacity="0%"/>

        <g class="ptz-control"/>
    </g>

    <!--    archive info -->
    <g class="time-chart" transform="translate(0 240)"></g>

</svg>
`
}