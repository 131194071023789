import {log} from "../common/app";

export class Alert {
    el;

    constructor(conf) {
        this.parent = conf.parent || document.body;
        this.text = conf.text || 'текст не задан';
    }

    display() {
        this.el = document.createElement('div');
        this.el.classList.add('widget-alert-wr');
        this.el.append(this.text);

        this.parent.insertAdjacentElement('beforeend', this.el);

        let parOffsets = this.parent.getBoundingClientRect();
        let xOffset = parOffsets.width / 10;
        this.el.style.marginLeft = `${xOffset}px`;
        this.el.style.marginRight = `${xOffset}px`;
        // this.el.style.width = `${parOffsets.width - xOffset * 2}px`;

        let elOffsets = this.el.getBoundingClientRect();
        this.el.style.marginTop = `-${elOffsets.y - parOffsets.y - (parOffsets.height - elOffsets.height) / 2}px`;

        this.attachEvents();
    }

    attachEvents() {
        this.el.addEventListener('click', () => this.clickHandler());
    }

    clickHandler() {
        this.el.remove();
    }

    clear() {
        this.el.removeEventListener('click', this.clickHandler);
        this.el.remove();
    }
}