import {WebRTCPlayer} from "./playerWebRTC";
import {log} from "./common/app";
import {startPlayMse} from "./playerMSE";
import {cache} from "./archive";
import {MSEPlayer_v2} from "./playerMSE2";
import {isIOS} from "./common/tools";
import {HLSPlayer} from "./playerHLS";

export let mediaProtocol = getMediaProtocol();

export function setMediaProtocol(prot) {
    localStorage.setItem('ptv_media_protocol', prot);
    mediaProtocol = prot;
    log(`player`, `def media protocol set to`, prot);
}


export function _playLive(cameraId, protocol = undefined) {
    protocol = protocol || getMediaProtocol();
    let streamNo = cache[cameraId]?.params?.streams?.length > 1 ? 1 : 0;

    log(`player`, `_play live ${cameraId}|${streamNo}`, protocol);

    switch (protocol) {
        case 'webrtc':
            startPlayWebRtc(cameraId, `/device/${cameraId}/channel/${streamNo}/live/webrtc`);
            break;

        case 'mse':
            startPlayMse(cameraId, `/device/${cameraId}/channel/${streamNo}/live/mse`)
            break;
    }
}

export function _playArchive(camId, archiveNo, startDateTime, speed, protocol = undefined, cbFunc) {
    protocol = protocol || getMediaProtocol();

    log(`player`, `_play archive ${archiveNo}`, camId, startDateTime, speed, protocol);

    let rtspUrl = `/device/${camId}/archive/${archiveNo}/${startDateTime}/${speed}`;
    switch (protocol) {
        case 'webrtc':
            startPlayWebRtc(camId, `${rtspUrl}/webrtc`, cbFunc);
            break;

        case 'mse':
            startPlayMse(camId, `${rtspUrl}/mse`, cbFunc);
            break;
    }
}

function getMediaProtocol() {
    const stored = localStorage.getItem('ptv_media_protocol');
    if (stored?.length) {
        return stored;
    }

    if (isIOS()) {
        log('player', 'iOS detected, select HLS');
        return 'hls';
    }

    log('player', 'select MSE by-default');

    localStorage.setItem('ptv_media_protocol', 'mse');

    return 'mse';
}

export function getPlayer(prot = undefined) {
    let protocol = prot || getMediaProtocol();
    switch (protocol) {
        case 'webrtc':
            return new WebRTCPlayer();

        case 'mse':
            return new MSEPlayer_v2();

        case 'hls':
            return new HLSPlayer();
    }
}