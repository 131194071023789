import dayjs from "dayjs";
import {fetchData, server} from "./common/app";

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

async function requestAndPrepareTimeLineData(cameraId, start, end, archiveNo = 0) {
    let startDateTime = dayjs(start).utc().format('YYYYMMDD[T]HHmmss');
    let endDateTime = dayjs(end).utc().format('YYYYMMDD[T]HHmmss');

    let url = `/device/archive/${archiveNo}/time_data/prepare/${cameraId}/${startDateTime}/${endDateTime}`
    let vData = (await fetchData(url)).payload;

    return createTimeLineData(vData);
}

function createTimeLineData(vData) {
    let preparedData = [[], [], []];
    vData["recordedIntervals"]?.forEach(interval => {
        preparedData[0].push({
            'timeRange': [
                convertToDayjs(interval.begin).toDate(),
                convertToDayjs(interval.end).toDate()
            ],
            'val': 'Записанное видео'
        });
    });

    vData["eventIntervals"]?.filter(ev => !!ev.timestamp).forEach(event => {
        let srt = convertToDayjs(event.timestamp);
        let dur = 'duration' in event ? dayjs(event.duration, 'HHmmss[.]SSS') : dayjs('000001.000', 'HHmmss[.]SSS');
        let end = srt.add(dur.millisecond(), 'ms').add(dur.second(), 's').add(dur.minute(), 'm').add(dur.hour(), 'h');

        preparedData[1].push({
            'timeRange': [
                srt.toDate(),
                end.toDate()
            ],
            'val': event.type
        });
    });

    vData["alerts"]?.forEach(alert => {
        preparedData[2].push({
            'timeRange': [
                convertToDayjs(alert.raisedAt).subtract(1.0, 's').toDate(),
                convertToDayjs(alert.raisedAt).add(1.0, 's').toDate()
            ],
            'val': alert.detectorName
        });
    });

    let intData = []
    for (let i = 0; i < preparedData.length; i++) {
        intData.push({
            'label': i,
            'data': preparedData[i]
        })
    }

    return [
        {
            'group': 'Комната',
            'data': intData
        },
    ];
}

function convertToDayjs(text) {
    return text.includes('.') ?
        dayjs.utc(text, 'YYYYMMDD[T]HHmmss[.]SSS') :
        dayjs.utc(text, 'YYYYMMDD[T]HHmmss');
}

export {requestAndPrepareTimeLineData};