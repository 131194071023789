import {fetchData_v2, hideUrlSensData, log, logError} from "./common/app";
import {wsFromHttpUrl} from "./common/websocket2";
import {wsStateSub, wsStateUnSub} from "./common/websocket_utils";

export class MSEPlayer_v2 {
    video = null;
    ws = null;
    sound = false;
    mseSourceBuffer = null;
    mse = null;
    mseQueue = [];
    mseStreamingStarted = false;
    promtv = null;
    auth = null;
    camId = null;
    checkTime = null;
    checkTimeCounter = 0;
    onTimeSyncCb = null;
    cid = null;
    url = null;
    packetTimeout = null;
    inited = false;
    errorCount = 0;
    onPlayListeners = [];
    reused = false;

    /**
     * @param {Object} params - параметры воспроизведения
     * @param {string} params.promtv - сервер ПромТВ
     * @param {string} params.auth - логин:пароль
     * @param {string} params.camId - идентификатор (название) камеры
     * @param {string} params.url - URL воспроизведения
     * @param {HTMLVideoElement} params.video - html элемент `video`
     * @param {function()} params.onTimeSyncCb - callback текущего времени воспроизведения
     */
    play(params) {
        this.promtv = params?.promtv || this.promtv;
        this.auth = params?.auth || this.auth;
        this.camId = params?.camId || this.camId;
        this.url = params?.url ? params.url + '/mse' : this.url;
        this.urlSafe = hideUrlSensData(this.url);
        this.video = params?.video || this.video;
        this.onTimeSyncCb = params?.onTimeSyncCb;

        // используем тот же плеер для проигрывания других видео
        if (!!this.mse && this.reused && !!this.cid?.length) {
            log(`mse_v2`, `play with existing MediaSource, devId:`, this.camId, this.urlSafe);
            // очистить старый mse
            // this.ws.send(JSON.stringify({type: 'stop', data: 'stop current media'}));
            // this.mse.removeSourceBuffer(this.mse.sourceBuffers[0]);
            this.reset();
            fetchData_v2(`${this.url}/${this.cid}`);
            return;
        }

        log(`mse_v2`, `play with new MediaSource`, this.camId, this.urlSafe);

        this.mse = new MediaSource();
        this.video.src = window.URL.createObjectURL(this.mse);
        this.initEvents();

        if (!this.reused) {
            this.onWsConnectStateChangeHandler = this.onWsConnectStateChangeHandler.bind(this);
            this.subscribeWsState();
        }
        this.reused = true;
    }

    initEvents() {
        this.video.onplay = this.onPlay.bind(this);

        let _this = this;
        this.mse.addEventListener('sourceopen', this.onMseOpen.bind(this));
        this.mse.addEventListener('sourceended', () => log(`mse_v2`, 'sourceended', _this.camId));
        this.mse.addEventListener('sourceclose', () => log(`mse_v2`, `sourceclose`, _this.camId));
        this.mse.addEventListener('error', () => log(`mse_v2`, 'error', _this.camId));
        this.mse.addEventListener('abort', () => log(`mes`, 'abort', _this.camId));
        this.mse.addEventListener('updatestart', () => log(`mse_v2`, 'updatestart', _this.camId));
        this.mse.addEventListener('update', () => log(`mse_v2`, 'update', _this.camId));
        this.mse.addEventListener('updateend', () => log(`mse_v2`, 'updateend', _this.camId));
        this.mse.addEventListener('addsourcebuffer', () => log(`mse_v2`, 'addsourcebuffer', _this.camId));
        this.mse.addEventListener('removesourcebuffer', () => log(`mse_v2`, 'removesourcebuffer', _this.camId));
    }

    onMseOpen() {
        log(`mse_v2`, `sourceopen`, this.camId);

        let ws_url = wsFromHttpUrl(this.url);

        log(`mse_v2`, `connecting websocket`, this.camId, hideUrlSensData(ws_url));

        this.ws = new WebSocket(ws_url);
        this.ws.binaryType = "arraybuffer";

        this.ws.onopen = (e) => {
            log(`mse_v2`, `websocket: connected`, this.camId);
            this.ws.send(JSON.stringify({type: 'info', data: 'mse client widget ws connected'}));
            this.setPacketTimeout();
        }
        this.ws.onclose = (e) => log(`mse_v2`, `websocket: closed`, this.camId);
        this.ws.onerror = (e) => log(`mse_v2`, 'websocket: error', this.camId);
        this.ws.onmessage = this.wsMsgHandle.bind(this);
    }

    wsMsgHandle(event) {
        // log(`mse_v2`, `ws message`, event.data);

        // this.checkStalled();// ложняки если не video за пределами видимой области

        let data = new Uint8Array(event.data);

        if (data[3] === 24) {
            this.inited = true;
            log(`mse_v2`, 'data: init_file', this.camId);
        }

        if (data[0] === 9) {
            let decoded_arr = data.slice(1);
            let mimeCodec;
            if (window.TextDecoder) {
                mimeCodec = new TextDecoder("utf-8").decode(decoded_arr);
            } else {
                mimeCodec = Utf8ArrayToStr(decoded_arr);
            }
            if (mimeCodec.indexOf(',') > 0) {
                this.sound = true;
            }
            log(`mse_v2`, this.camId, 'codec: ' + mimeCodec);

            try {
                this.mseSourceBuffer = this.mse.addSourceBuffer('video/mp4; codecs="' + mimeCodec + '"');
                this.mseSourceBuffer.mode = "sequence";
                this.mseSourceBuffer.addEventListener("updateend", this.pushPacket.bind(this));

                this.setPacketTimeout();

            } catch (e) {
                log(`mse_v2`, `add source error`, this.camId, e);
            }

        } else if (data[0] === 0 && !!this.mseSourceBuffer && this.inited) {
            this.readPacket(event.data);

        } else if (data.length === 0) {
            let msg = JSON.parse(event.data);
            switch (msg.type) {
                case 'time':
                    if (typeof this.onTimeSyncCb === 'function') {
                        this.onTimeSyncCb(msg.data);
                    }
                    break;

                case 'cid':
                    log(`mse_v2`, `cid received for`, this.camId, msg.data);
                    this.cid = msg.data;
                    break;

                case 'error':
                    logError(`mse`, `error`, this.camId, msg.data);
                    break;

                default:
                    log(`mse_v2`, `unknown message`);
            }
        }
    }

    readPacket(packet) {
        this.setPacketTimeout();

        this.correctPlayTime();

        if (!this.mseStreamingStarted) {
            try {
                this.mseSourceBuffer.appendBuffer(packet);
                this.mseStreamingStarted = true;
                this.errorCount = 0;

            } catch (e) {
                let bufInd = this.video.buffered.length - 1;
                let info;
                if (bufInd >= 0) {
                    info = `${this.video.buffered.start(bufInd)} > ${this.video.currentTime} > ${this.video.buffered.end(bufInd)}`;
                    info += `,  ${this.video.buffered.end(bufInd) - this.video.currentTime}`;
                }

                log(`mse_v2`, 'readPacket error', this.camId, info ? info : '', e, this.video.error);

                if (!this.onTimeSyncCb) {
                    this.errorCount++;
                    if (this.errorCount > 50) {
                        this.ws.send(JSON.stringify({
                            type: 'error',
                            data: '>50 append buffer errors, reconnecting...'
                        }));

                        this.refresh('reconnecting due >50 append buffer errors', e);
                    }

                    log(`mse_v2`, `read packet error (${this.errorCount})`, e);
                }
            }

            return;
        }

        this.mseQueue.push(packet);
        this.pushPacket();
    }

    setPacketTimeout() {
        this.stopPacketTimeout(false);

        this.packetTimeout = setTimeout(() => {
            log(`mse_v2`, `packet timeout ${this.promtv}/${this.camId}`);
            if (this.ws.readyState === 1) {
                this.ws.send(JSON.stringify({type: 'error', data: 'packet timeout, reconnecting...'}));
            }
            this.refresh('reconnecting due packet timeout');
        }, 20_000);
    }

    pushPacket() {
        if (!this.mseSourceBuffer) {
            return;
        }

        if (!this.mseSourceBuffer.updating) {
            if (this.mseQueue.length > 0) {
                let packet = this.mseQueue.shift();

                try {
                    this.mseSourceBuffer.appendBuffer(packet)

                } catch (e) {
                    this.errorCount++;
                    if (this.errorCount > 50) {
                        log(`mse_v2`, `MediaSource buffer, >50 add packet errors`, this.camId, e);

                        this.ws.send(JSON.stringify({
                            type: 'error',
                            data: '>50 append buffer errors, reconnecting...'
                        }));

                        this.refresh('reconnecting due >50 append buffer errors (push packet)', e);
                    }

                    log(`mse_v2`, `push packet error (${this.errorCount})`, e);
                }

            } else {
                this.mseStreamingStarted = false;
            }
        }

        this.correctPlayTime();
    }

    correctPlayTime() {
        if (this.video?.buffered?.length > 0) {
            let bufTime = this.video.buffered.end(this.video.buffered.length - 1);

            if (typeof document.hidden !== "undefined" && document.hidden) {
                if (!this.sound) {
                    this.video.currentTime = bufTime - 0.5;
                }
            } else { // Math.abs() - на случай зависания видео, когда video.currentTime >> video.buffered.end
                if (Math.abs(bufTime - this.video.currentTime) > 1) {
                    this.video.currentTime = bufTime - 0.5;
                }
            }
        }
    }

    checkStalled() {
        if (!!this.video.currentTime) {
            if (this.video.currentTime === this.checktime) {
                this.checktimecounter += 1;
            } else {
                this.checktimecounter = 0;
            }
        }
        if (this.checktimecounter > 10) {
            // log(`mse_v2`, 'player not move', this.camId, this.video.currentTime, this.checktime);
            // _playLive(uuid, videoPlayerVar.index(), channel, 'mse');//todo
        }
        this.checktime = this.video.currentTime;
    }

    destroy() {
        log(`mse_v2`, this.promtv, this.camId, 'destroying, mse readyState', this.mse.readyState);

        this.mseSourceBuffer?.removeEventListener("updateend", this.pushPacket);
        this.mseSourceBuffer = null;

        if (!!this.packetTimeout) {
            this.stopPacketTimeout(true);
            log(`mse_v2`, 'clear packet timeout (destroy)', this.promtv, this.camId);
        }
        if (!!this.ws) {
            // this.ws.onclose = null;
            this.ws.close(1000, "stop streaming");
        }
        wsStateUnSub(this.promtv, this.auth, `mse player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);

        // чтобы видео не зависало при показе и скрытии виджетов
        if (this.video) {
            this.video?.pause();
            this.video.onloadeddata = null;
            this.video.src = '';
            this.video.load();
            // this.video = null; //todo ошибка при переподключении, проверить накопление мусора
        }

        this.reused = false;

        log(`mse_v2`, 'Event: PlayerDestroy', this.camId, "media state", this.mse.readyState);

        this.clearVars();
    }

    reset() {
        // this.onTimeSyncCb = null;
        this.inited = false;
        this.errorCount = 0;
        // this.onPlayListeners.shift();
        this.video.pause(); // для повторного формирования
        this.video.play(); // события video.onPlay()
    }

    clearVars() {
        this.video = null;
        this.ws = null;
        this.mseSourceBuffer = null;
        this.mse = null;
        this.onTimeSyncCb = null;
        this.packetTimeout = null;
        this.onPlayListeners = [];
    }

    onPlay(e) {
        log(`mse_v2`, 'onPlay', this.promtv, this.camId);

        this.onPlayListeners.forEach(l => {
            if (typeof l === 'function') {
                l();
            }
        })
    }

    onPlayListener(cb) {
        if (typeof cb === 'function') {
            this.onPlayListeners.push(cb);
        }
    }

    stopPacketTimeout(logged) {
        clearTimeout(this.packetTimeout);
        if (logged) {
            log(`mse_v2`, 'clear packet timeout', this.camId);
        }
    }

    subscribeWsState() {
        wsStateSub(this.promtv, this.auth, `mse player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);
    }

    onWsConnectStateChangeHandler(msg) {
        switch (msg.type) {
            case 'reconnect':
                this.reconnect();
                break;

            case 'disconnect':
                this.stopPacketTimeout(true);
                break;
        }
    }

    reconnect() {
        this.destroy();
        this.play();
    }

    refresh(...details) {
        if (!this.onTimeSyncCb) {
            log(`mse_v2`, this.promtv, this.camId, 'refresh connection due', details);
            this.play();
        } else {
        }
    }
}

function Utf8ArrayToStr(array) {
    let out, i, len, c;
    let char2, char3;
    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 7:
                out += String.fromCharCode(c);
                break;
            case 13:
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
        }
    }
    return out;
}