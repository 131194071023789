import './polygon.css'
import {getMousePosition, PointNS} from "./pointNS";
import {ContextMenuNS} from "./dropdownNS";

export class PolygonNS {
    el;
    vertexes = [];
    parentBBox;

    constructor(params) {
        this.parent = params.parent;
        this.coords = Array.isArray(params.coords) ? params.coords : [];
        this.oncontext = typeof params.oncontext === 'function' ? params.oncontext : function (pos) {}

        this.init();
        this.draw();
        this.addEvents();
    }

    init() {
        this.el = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
        // this.el.classList.add('draggable');
        this.el.classList.add('draggable-polygon');
        this.parent.append(this.el);
        this.parentBBox = this.parent.getBBox();

        this.coords.forEach(coord => {
            let v = this.createVertex(coord, this);
            this.vertexes.push(v);
        })
    }

    addEvents() {
        this.el.addEventListener('contextmenu', e => {
            e.preventDefault();
            let pos = getMousePosition(this.parent, e.clientX, e.clientY);
            this.oncontext(pos);
        });
    }

    createVertex(coord, polygon) {
        let v = new PointNS({
            parent: this.parent,
            parentBBox: this.parentBBox,
            coord: coord,
            ondraged: (pos) => {
                v.coord.x = pos.x;
                v.coord.y = pos.y;
                this.refresh();
            },
            oncontextmenu: function (pos) {
                new ContextMenuNS({
                    parent: polygon.parent,
                    x: pos.x,
                    y: pos.y,
                    items: [
                        {text: "Добавить", onaction: () => polygon.addVertex(v)},
                        {text: "Удалить", onaction: () => polygon.removeVertex(v)},
                    ],
                    fontSize: "10px"
                })
            },
        })
        return v;
    }

    draw() {
        let vertexes = this.vertexes.map(el => el.coord.x + ',' + el.coord.y).join(' ');
        this.el.setAttribute('points', vertexes);
        this.vertexes.forEach(v => v.draw());
    }

    refresh() {
        let vertexes = this.vertexes.map(el => el.coord.x + ',' + el.coord.y).join(' ');
        this.el.setAttribute('points', vertexes);
    }

    addVertex(p) {
        let index = this.vertexes.indexOf(p);
        let coord = {};
        if (index === this.vertexes.length - 1) {
            coord.x = (p.coord.x + this.vertexes[0].coord.x) / 2;
            coord.y = (p.coord.y + this.vertexes[0].coord.y) / 2;
        } else {
            coord.x = (p.coord.x + this.vertexes[index + 1].coord.x) / 2;
            coord.y = (p.coord.y + this.vertexes[index + 1].coord.y) / 2;
        }

        let v = this.createVertex(coord, this);
        v.draw();
        this.vertexes.splice(index + 1, 0, v);
        this.refresh();
    }

    removeVertex(p) {
        if (this.vertexes.length < 4) {
            return
        }

        p.remove();
        let index = this.vertexes.indexOf(p);
        this.vertexes.splice(index, 1);
        this.refresh();
    }

    remove() {
        this.el.remove();
        this.vertexes.forEach(v => v.remove());
    }
}