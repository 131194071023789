import {log} from "./app";


export function parseDomData($el, ...ignore) {
    ignore = ignore !== undefined && Array.isArray(ignore) ? ignore : [];
    let obj = {};

    $el.find(`input, select`).each(function () {
        if (ignore.indexOf(this.name) >= 0) {
            return;
        }

        switch (this.type) {
            case 'checkbox':
                obj[this.name] = this.checked;
                break;

            case 'number':
                obj[this.name] = parseInt(this.value);
                break;

            default:
                obj[this.name] = this.value;
        }
    })

    return obj;
}

export function displayData(obj, $el, rules = undefined) {
    rules = rules !== undefined ? rules : {};

    $el.find(`input, select`).each(function () {
        if (this.name in obj) {
            switch (this.type) {
                case 'checkbox':
                    $(this).attr('checked', obj[this.name]);
                    break;

                default:
                    if (this.name in rules && typeof rules[this.name] === 'function') {
                        this.value = rules[this.name](obj[this.name]);
                    } else {
                        this.value = obj[this.name];
                    }
            }
        }
    })
}