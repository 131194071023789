import {log} from "../common/app";

export class Dropdown {
    isDisplayed;
    el;

    constructor(conf) {
        this.parent = conf.parent?.tagName === 'DIV' ? conf.parent : document.body;
        this.initiator = conf.initiator || undefined;
        this.rightBtn = conf.rightBtn || false;
        this.items = conf.items || [];
        this.freeze = conf.freeze || false;
        this.appearance = conf.appearance || 'ru';
        this.selectedIndexSupplier = conf.selectedIndexSupplier || function () {return -1};

        this.attachEvents();
    }

    display(e) {
        this.el = document.createElement('div');
        this.el.classList.add('my-dropdown');

        let drdCont = document.createElement('div');
        drdCont.classList.add('my-dropdown-content');

        this.items.forEach(item => {
            let it = document.createElement('div');
            let text = document.createTextNode(item.name);
            it.appendChild(text);

            if (this.items.indexOf(item) === this.selectedIndexSupplier()) {
                it.classList.add('selected');
            }

            it.addEventListener('click', e => {
                if (typeof item.action == 'function') {
                    item.action();
                    this.hide();
                }
            })
            drdCont.append(it);
        })

        this.el.append(drdCont);
        this.parent.append(this.el);

        let parOffsets = this.parent.getBoundingClientRect();
        if (this.parent.style.position === 'static' || this.parent.style.position === '') {
            this.parent.style.position = 'relative';
        }

        switch (this.appearance) {
            case 'lu':
                break;

            case 'ld':
                this.el.style.top = `${e.clientY - parOffsets.y}px`;
                this.el.style.left = `${e.clientX - parOffsets.x - this.el.offsetWidth}px`;
                break;

            case 'ru':
                this.el.style.top = `${e.clientY - parOffsets.y - this.el.offsetHeight}px`;
                this.el.style.left = `${e.clientX - parOffsets.x}px`;
                break;

            case 'rd':
                break;
        }

        if (!this.freeze) {
            this.el.addEventListener('mouseleave', e => setTimeout(() => this.hide(), 750));
            window.addEventListener('click', e => setTimeout(() => this.hide(), 250));
        }

        this.isDisplayed = true;
    }

    handleClick(e) {
        if (!this.isDisplayed) {
            this.display(e);
        } else {
            this.hide();
        }
    }

    handleWindowResize(e) {
        if (this !== undefined) {
            this.hide();
        }
    }

    attachEvents() {
        this.handleClick = this.handleClick.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);

        if (this.initiator) {
            this.initiator.addEventListener(this.rightBtn ? 'contextmenu' : 'click', this.handleClick);
        }
        window.addEventListener('resize', this.handleWindowResize);
    }

    removeEventHandlers() {
        if (this.initiator) {
            this.initiator.removeEventListener(this.rightBtn ? 'contextmenu' : 'click', this.handleClick);
        }
        window.removeEventListener('resize', this.handleWindowResize);
    }

    hide() {
        if (this.el !== undefined) {
            this.el.remove();
        }
        this.isDisplayed = false;
    }

    remove() {
        this.removeEventHandlers();
    }
}