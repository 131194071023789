import {hideUrlSensData, log, logError, serverWs} from "./app";

let ws = null;
let queue = [];
let sendQueue = [];
let autoReconnect = false;
let subscriptions = [];
let wsFatalError = false;
let isReconnected = false;
let ping;
let prevState = -1;

export function isWSCreated() {
    return ws !== null;
}

export function isWSStarted() {
    return isWSCreated() && ws.readyState === 1;
}

export function openWebSocket() {
    if (window.promtv?.page === 'login' ) {
        return;
    }

    if (isWSCreated()) {
        return
    }

    // передача сведений о реальном пользователе (для Гитеи)
    let wsUrl;
    try {
        wsUrl = serverWs + `/ws?real=${USER_INFO.login}`;
    } catch (e) {
        wsUrl = serverWs + `/ws`;
    }
    log(`ws`,`starting`, hideUrlSensData(wsUrl));

    try {
        ws = new WebSocket(wsUrl);
        wsFatalError = false;

        ws.onopen = function (e) {
            log(`ws`,"connected", hideUrlSensData(wsUrl));
            autoReconnect = true;
            prevState = 1;

            publish({type: "connect"});

            while (sendQueue.length > 0) {
                ws.send(sendQueue.shift());
            }

            if (isReconnected) {
                publish({type: "reconnect"});
            }
            isReconnected = true;

            ping = setInterval(()=> {
                ws.send(JSON.stringify({
                    type: 'ping'
                }))
            }, 10_000)
        };

        ws.onmessage = function (event) {
            let msg = JSON.parse(event.data);
            log(`ws`,"<-- in msg", msg);
            handleInMsg(msg);
        };

        ws.onclose = function (event) {
            log(`ws`,"closed");

            if (prevState === 1) {
                publish({type: "disconnect"});
            }
            prevState = 3;

            if (autoReconnect) {
                reconnect();
            }
        };

        ws.onerror = function (error) {
            log(`ws`,"ws error");
            reconnect();
        };

    } catch (e) {
        wsFatalError = true;
        logError(`ws`,'ws creating error', `${e.name} : ${e.message}`);
    }
}

function reconnect() {
    ws = null;
    clearInterval(ping);
    setTimeout(() => openWebSocket(), 10_000);
}

export function closeWebSocket() {
    log(`ws`,'ws closing');
    clearInterval(ping);
    autoReconnect = false;
    isReconnected = false;
    if (isWSCreated()) {
        ws.close();
    }
    ws = null;
}

function handleInMsg(msg) {
    publish(msg);
}

export function sendMsg(msg) {
    if (isWSStarted()) {
        ws.send(JSON.stringify(msg))
    } else if (!wsFatalError) {
        sendQueue.push(JSON.stringify(msg));
    }
}

export function subscribeDev(camId) {
    log(`ws`,`subscribing to`, camId);
    sendMsg({
        type: 'subscribe',
        data: camId
    });
}

export function subscribeDutyEvents() {
    log(`ws`,`subscribing to duty events`);
    sendMsg({
        type: 'subscribeAttention'
    });
}

export function unSubscribeDev(camId) {
    log(`ws`,`unsubscribing from`, camId);
    sendMsg({
        type: 'unsubscribe',
        data: camId
    });
}

export function subscribeWsMsg(cb, camId) {
    if (subscriptions.indexOf(cb) >= 0) {
        log(`ws sub`, 'sub not added, exists already', subscriptions.length, subscriptions);
        return;
    }

    subscriptions.push(cb);

    log(`ws sub`, camId, 'add sub', subscriptions.length, subscriptions);

    queue.forEach(msg => {
        log(`ws`,`publish from queue for ${camId}`, msg);
        cb(msg);
    })
}

export function unsubscribeWsMsg(cb) {
    let index = subscriptions.indexOf(cb);
    if (index !== -1) {
        subscriptions.splice(index, 1);
        log(`ws sub`, 'unsubscribed', subscriptions.length, subscriptions);
    }
}

function publish(msg) {
    /*if (subscriptions.length === 0) {
        queue.push(msg);
    }*/

    subscriptions.forEach(sub => {
        if (sub) {
            sub(msg);
        }
    })
}

export function clearData() {
    subscriptions = [];
    sendQueue = [];
    queue = [];
}

