import {log} from "../common/app";

export class ContextMenuItemNS {
    el;
    backEl;
    textEl;
    hoverEl;
    width;
    height;

    constructor(conf) {
        this.text = conf.text || '';
        this.fontSize = conf.fontSize || "10px";
        this.onaction = typeof conf.onaction === 'function' ? conf.onaction : function () {
        }
        this.x = conf.x || 0;
        this.y = conf.y || 0;
        this.width = conf.width || 0;
        this.space = conf.space || 5;
        this.parent = conf.parent;

        this.draw();
        this.addEvents();
    }

    init() {

    }

    draw() {
        let xmlns = 'http://www.w3.org/2000/svg';

        this.el = document.createElementNS(xmlns, 'g');

        this.textEl = document.createElementNS(xmlns, 'text');
        this.textEl.style.fontSize = this.fontSize;
        this.textEl.textContent = this.text;
        this.textEl.classList.add('context-menu-text');
        this.textEl.setAttribute('x', this.x + 3 * this.space);

        this.backEl = document.createElementNS(xmlns, 'rect');
        this.backEl.classList.add('context-menu-background');
        this.backEl.setAttribute('x', this.x);
        this.backEl.setAttribute('y', this.y);


        this.hoverEl = document.createElementNS(xmlns, 'rect');
        this.hoverEl.classList.add('context-menu-hover');
        this.hoverEl.setAttribute('x', this.x);
        this.hoverEl.setAttribute('y', this.y);


        this.el.append(this.backEl, this.textEl, this.hoverEl);
        this.parent.append(this.el);

        let bbox = this.textEl.getBBox();
        this.width = bbox.width + 6 * this.space
        this.height = bbox.height + 2 * this.space

        this.textEl.setAttribute('y', this.y + bbox.height + this.space - 3);
        this.backEl.setAttribute('width', this.width);
        this.backEl.setAttribute('height', this.height);
        this.hoverEl.setAttribute('width', this.width);
        this.hoverEl.setAttribute('height', this.height);
    }

    addEvents() {
        this.hoverEl.addEventListener('mouseover', (e) => {
            this.backEl.classList.add('hover');
        })

        this.hoverEl.addEventListener('mouseleave', (e) => {
            this.backEl.classList.remove('hover');
        })

        this.hoverEl.addEventListener('click', (e) => {
            this.onaction();
        })
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    setWidth(w) {
        this.width = w;
        this.backEl.setAttribute('width', w);
        this.hoverEl.setAttribute('width', w);
    }
}


export class ContextMenuNS {
    el;
    mnuItems = [];

    constructor(conf) {
        this.x = conf.x || 0;
        this.y = conf.y || 0;
        this.parent = conf.parent;
        this.items = Array.isArray(conf.items) ? conf.items : [];
        this.fontSize = conf.fontSize;

        this.draw();
        this.addEvents();
    }

    draw() {
        this.el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.el.classList.add('context-menu');
        this.parent.append(this.el);

        let pbb = this.parent.getBBox();
        let parentSize = {width: pbb.width, height: pbb.height};

        let y = this.y;
        let maxWidth = 0;
        let height = 0;
        this.items.forEach(item => {
            let menuItem = new ContextMenuItemNS({
                parent: this.el,
                text: item.text,
                fontSize: this.fontSize,
                onaction: () => {
                    this.hide();
                    item.onaction();
                },
                x: this.x,
                y: y,
            });
            y += menuItem.getHeight();
            maxWidth = Math.max(maxWidth, menuItem.getWidth());
            height += menuItem.getHeight();
            this.mnuItems.push(menuItem);
        })
        this.mnuItems.forEach(mnuItem => {
            mnuItem.setWidth(maxWidth);
        })

        let translateX = this.x + maxWidth > parentSize.width ? - maxWidth : 0;
        let translateY = this.y + height > parentSize.height ? - height : 0;
        this.el.setAttribute('transform', `translate(${translateX},${translateY})`);
    }

    addEvents() {
        this.el.addEventListener('mouseleave', (e) => {
            setTimeout(() => this.hide(), 250);
        })
    }

    hide() {
        this.el.remove();
    }
}