export class CamScrFAwBtn {
    el;

    constructor(conf) {
        this.name = conf.name || undefined;
        this.x = conf.x || 0;
        this.y = conf.y || 0;
        this.action = conf.action || function () {
        };
        this.parent = conf.parent || document.body;
        this.triggerable = conf.triggerable || false;
        this.activeClass = conf.activeClass || 'active';
        this.disableClass = conf.disableClass || 'disabled';
        this.defClasses = conf.defClasses || "";
    }

    display() {
        this.el = document.createElementNS("http://www.w3.org/2000/svg", 'foreignObject');
        this.el.setAttribute('x', this.x);
        this.el.setAttribute('y', this.y);
        this.el.setAttribute('width', "2rem");
        this.el.setAttribute('height', "2rem");

        const textEl = document.createElement('text');
        const classes = this.defClasses.split(' ');
        textEl.classList.add(...classes);

        this.el.appendChild(textEl);

        if (this.name !== undefined) {
            textEl.classList.add(this.name);
        }

        this.el.addEventListener('click', () => {
            if (this.triggerable) {
                if (this.el.classList.contains(this.activeClass)) {
                    this.el.classList.remove(this.activeClass);
                } else {
                    this.el.classList.add(this.activeClass);
                }

                this.el.classList(this.activeClass);
            }

            if (typeof this.action == "function") {
                this.action();
            }
        })

        this.parent.append(this.el);

        return this;
    }

    disable() {
        if (this.el.classList.contains(this.disableClass)) {
            this.el.classList.remove(this.disableClass);
        } else {
            this.el.classList.add(this.disableClass);
        }

        return this;
    }

    remove() {
        this.el.remove();
        this.el = undefined;
    }

}
