import {fetchData_v2, hideUrlSensData, log, logError} from "./common/app";
import {wsFromHttpUrl} from "./common/websocket2";
import {wsStateSub, wsStateUnSub} from "./common/websocket_utils";
import {isIOS} from "./common/tools";
import Hls from "hls.js";

export class HLSPlayer {
    video = null;
    ws = null;
    promtv = null;
    auth = null;
    camId = null;
    onTimeSyncCb = null;
    cid = null;
    url = null;
    packetTimeout = null;
    onPlayListeners = [];
    hls = null;

    /**
     * @param {Object} params - параметры воспроизведения
     * @param {string} params.promtv - сервер ПромТВ
     * @param {string} params.auth - логин:пароль
     * @param {string} params.camId - идентификатор (название) камеры
     * @param {string} params.url - URL воспроизведения
     * @param {HTMLVideoElement} params.video - html элемент `video`
     * @param {function()} params.onTimeSyncCb - callback текущего времени воспроизведения
     */
    async play(params) {
        this.promtv = params?.promtv || this.promtv;
        this.auth = params?.auth || this.auth;
        this.camId = params?.camId || this.camId;
        this.url = params?.url ? params.url + '/hls' : this.url;
        this.urlSafe = hideUrlSensData(this.url);
        this.video = params?.video || this.video;
        this.onTimeSyncCb = params?.onTimeSyncCb;

        log('hls', 'starting play', this.url);

        if (this.ws && this.cid?.length) {
            let info = await fetchData_v2(`${this.url}/${this.cid}`);
            this.initHls(info.url);

            return;
        }

        this.connectWS();

        this.onPlay = this.onPlay.bind(this);
    }

    connectWS() {
        let wsUrl = wsFromHttpUrl(this.url);

        log(`hls ws`, `connecting websocket`, this.camId, hideUrlSensData(wsUrl));

        this.ws = new WebSocket(wsUrl);
        this.ws.onopen = (e) => {
            log(`hls ws`, `websocket: connected`, this.camId);
            this.ws.send(JSON.stringify({topic: 'info', data: 'mse client widget ws connected'}));
            this.setPacketTimeout();
        }
        this.ws.onclose = (e) => log(`hls ws`, `websocket: closed`, this.camId);
        this.ws.onerror = (e) => log(`hls ws`, 'websocket: error', this.camId);
        this.ws.onmessage = this.wsMsgHandle.bind(this);
        this.onWsConnectStateChangeHandler = this.onWsConnectStateChangeHandler.bind(this);

        this.subscribeWsState();
    }

    wsMsgHandle(event) {
        this.setPacketTimeout();
        const msg = JSON.parse(event.data);
        // log(`hls ws`, `ws message`, msg);

        switch (msg.topic) {
            case 'init':
                log(`hls ws`, `ws message`, msg);
                this.cid = msg.cid;
                this.initHls(msg.url);
                break;

            case "time":
                if (this.onTimeSyncCb) {
                    this.onTimeSyncCb(msg.time);
                }
                break;
        }
    }

    initHls(url) {
        log('hls', 'init play url', url);

        if (isIOS()) {
            log('hls', 'play HLS with native support');
            this.video.src = url;
            this.video.load();

        } else if (Hls.isSupported()) {
            log('hls', 'play HLS with lib')
            if (this.hls) {
                this.hls.destroy();
                this.hls = null;
            }

            this.hls = new Hls({manifestLoadingTimeOut: 60000});
            this.hls.loadSource(url);
            this.hls.attachMedia(this.video);
            this.hls.on('error', function (e) {
                console.log(e);
            })
        } else {
            log.error('hls', 'HLS playing not supported in this environment');
        }

        this.video.onplay = this.onPlay;
    }

    setPacketTimeout() {
        this.stopPacketTimeout(false);

        this.packetTimeout = setTimeout(() => {
            if (this.onTimeSyncCb) {
                return;
            }

            log(`hls ws`, `packet timeout ${this.promtv}/${this.camId}`);
            /*if (this.ws.readyState === 1) {
                this.ws.send(JSON.stringify({type: 'error', data: 'packet timeout, reconnecting...'}));
            }*/
            this.refresh('reconnecting due packet timeout');
        }, 20_000);
    }


    destroy() {
        log(`hls`, this.promtv, this.camId);

        if (!!this.packetTimeout) {
            this.stopPacketTimeout(true);
            log(`hls`, 'clear packet timeout (destroy)', this.promtv, this.camId);
        }
        if (!!this.ws) {
            // this.ws.onclose = null;
            this.ws.close(1000, "stop streaming");
        }
        wsStateUnSub(this.promtv, this.auth, `hls player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);

        // чтобы видео не зависало при показе и скрытии виджетов
        if (this.video) {
            this.video?.pause();
            this.video.onloadeddata = null;
            this.video.src = '';
            this.video.load();
            // this.video = null; //todo ошибка при переподключении, проверить накопление мусора
        }

        log(`hls`, 'Event: PlayerDestroy', this.camId);

        this.clearVars();
    }

    reset() {
        // this.onTimeSyncCb = null;
        // this.onPlayListeners.shift();
        this.video.pause(); // для повторного формирования
        this.video.play(); // события video.onPlay()
    }

    clearVars() {
        this.video = null;
        this.ws = null;
        this.onTimeSyncCb = null;
        this.packetTimeout = null;
        this.onPlayListeners = [];
    }

    onPlay(e) {
        log(`hls`, 'onPlay', this.promtv, this.camId);

        this.onPlayListeners.forEach(l => {
            if (typeof l === 'function') {
                l();
            }
        })
    }

    onPlayListener(cb) {
        if (typeof cb === 'function') {
            this.onPlayListeners.push(cb);
        }
    }

    stopPacketTimeout(logged) {
        clearTimeout(this.packetTimeout);
        if (logged) {
            log(`hls ws`, 'clear packet timeout', this.camId);
        }
    }


    subscribeWsState() {
        wsStateSub(this.promtv, this.auth, `mse player ${this.camId} (connect state change)`, this.onWsConnectStateChangeHandler);
    }

    onWsConnectStateChangeHandler(msg) {
        switch (msg.type) {
            case 'reconnect':
                this.reconnect();
                break;

            case 'disconnect':
                this.disconnect();
                break;
        }
    }

    disconnect() {
        this.stopPacketTimeout(true);
        this.ws.close(1000, "stop streaming");
        this.ws = null;
        this.cid = null;
    }

    reconnect() {
        // this.destroy();
        this.play();
    }

    refresh(...details) {
        if (!this.onTimeSyncCb) {
            log(`hls`, this.promtv, this.camId, 'refresh connection due', details);
            this.play();
        } else {
        }
    }
}