import {fetchData_v2, log} from "./app";

let sessionCache = {};
let waitList = {};

export async function sessionInfo(promtv, auth) {
    let id = promtv + '|' + auth;

    if (id in sessionCache) {
        return sessionCache[id];
    }
    if (id in waitList) {
        return new Promise(resolve => {
            waitList[id].push(resolve);
        });
    }

    waitList[id] = [];

    let info = (await fetchData_v2(promtv + '/user/session', auth)).payload;
    sessionCache[id] = info;

    for (let res of waitList[id] || []) {
        res(info);
    }
    delete waitList[id];

    log(`session`, promtv, 'session info', info);

    return info;
}