
export function getMousePosition(svg, x, y) {
    let CTM = svg.getScreenCTM();
    return {
        x: (x - CTM.e) / CTM.a,
        y: (y - CTM.f) / CTM.d,
    }
}

export class PointNS {
    el;
    isDragged = false;
    parentBBox;

    constructor(params) {
        this.parent = params.parent;
        this.coord = params.coord || {};
        this.ondraged = typeof params.ondraged === 'function' ? params.ondraged : function (pos) {}
        this.oncontextmenu = typeof params.oncontextmenu === 'function' ? params.oncontextmenu : function (pos) {}
        this.parentBBox = params.parentBBox || this.parent.getBBox();

        this.init();
    }

    init() {
        this.el = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        this.el.classList.add('draggable')
        this.el.classList.add('draggable-point')
        this.parent.append(this.el);

        this.addEvents();
    }

    draw() {
        this.el.setAttribute('r', 4);
        this.el.setAttribute('cx', this.coord.x || 0);
        this.el.setAttribute('cy', this.coord.y || 0);
        return this.el;
    };

    addEvents() {
        this.el.addEventListener('mousedown', e => this.startDrag(e));
        this.el.addEventListener('mouseup', e => this.endDrag(e));
        this.parent.addEventListener('mouseleave', e => this.endDrag(e));
        this.parent.addEventListener('mousemove', e => this.drag(e));
        this.el.addEventListener('contextmenu', e => this.contextMenu(e));
    }

    startDrag(e) {
        e.preventDefault();
        this.isDragged = true;
    }

    drag(e) {
        e.preventDefault();
        if (this.isDragged) {
            const MAGVAL = 10;
            let pos = getMousePosition(this.parent, e.clientX, e.clientY);
            let px = pos.x < MAGVAL ? 0 : this.parentBBox.width - pos.x < MAGVAL ? this.parentBBox.width : pos.x;
            let py = pos.y < MAGVAL ? 0 : this.parentBBox.height - pos.y < MAGVAL ? this.parentBBox.height : pos.y;
            this.el.setAttributeNS(null, 'cx', px);
            this.el.setAttributeNS(null, 'cy', py);

            pos.x = px;
            pos.y = py;

            this.ondraged(pos);
        }
    }

    endDrag(e) {
        e.preventDefault();
        if (this.isDragged) {
            this.isDragged = false;
        }
    }

    contextMenu(e) {
        e.preventDefault();
        let pos = getMousePosition(this.parent, e.clientX, e.clientY);
        this.oncontextmenu(pos);
    }

    remove() {
        this.el.remove();
    }
}