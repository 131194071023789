import {cache, checkAndSwitchArchiveOff, createTimeline, switchArchive} from "./archive";
import {fetchData, log, requestExport, server, setIceservers, setServer} from "./common/app";
import dayjs from "dayjs";
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import {
    blockPtz,
    checkAndSwitchPtzOff,
    informPtzUsed,
    moveToPosition,
    switchPtz,
    switchPtzOff,
    unblockPtz
} from "./ptzControl";
import {_playLive} from "./player";
// import './camera.css';
import {hideHeaderBtns, initHeader} from "./cameraHeader";
import {
    clearData,
    closeWebSocket,
    isWSCreated,
    openWebSocket,
    sendMsg,
    subscribeDev,
    subscribeWsMsg,
    unSubscribeDev,
    unsubscribeWsMsg
} from "./common/websocket";
import utc from 'dayjs/plugin/utc';
import {hasAlarm, hasAttention, hasFault, hasMotion} from "./common/stateUtils";
import {checkPermissions} from "./common/permissions";
import {Alert} from "./components/alert";
import {Dialog} from "./components/dialog";
import {displayPrivateMask} from "./common/privateMask";
import {appSession, requestAppSession} from "./common/session";
import {stopMse} from "./playerMSE";
import {
    fullscreenEnabled,
    fullscreenOff,
    fullscreenOn,
    getFullscreenElement,
    hasFullscreenElement
} from "./common/fullscreen";

dayjs.extend(AdvancedFormat);
dayjs.extend(utc)

/***
 * Воспроизведение видео
 *
 * @deprecated замена на new Widget.playLive()
 *
 * @param {HTMLElement} svg
 * @param {string} serverUrl Адрес сервера ПромТВ
 * @param ptzCb
 * @returns {Promise<void>}
 */
export async function playLive(svg, serverUrl = undefined, ptzCb = undefined) {
    let camId = $(svg).attr('id');

    await connectToServer(serverUrl);

    log(`camera`, `play live ${camId}, server:`, server);

    if (await init(svg, ptzCb)) {
        _playLive(camId);
    }
}

/***
 *
 * @param req
 *      {
 *         cameraElement: , =svg
 *         cameraId: ,
 *         server: , =promtv server
 *         auth:,
 *         startTime: =startPlay time
 *     }
 * @returns {Promise<void>}
 */
export async function playArchive(req) {

}

/***
 *
 * @param req
 *       {
 *           cameraElement: , = svg
 *           server:, = promtv server
 *           scada_reference:, 
 *           auth:
 *       }
 * @returns {Promise<void>}
 */
export async function playArchiveRef(req) {

}

async function init(svg, ptzCb) {
    let $svg = $(svg);
    let cameraId = $svg.attr('id');

    log(`camera`, `init camera`, cameraId);

    let answer = await fetchData(`/device/info/${cameraId}`);
    if (!answer.status) {
        new Alert({
            parent: $svg.parent()[0],
            text: `Отсутствуют разрешения для просмотра камеры`
        }).display();

        hideHeaderBtns($svg);
        stopLoading($svg);

        return false
    }

    let camera = answer.payload;

    cache[cameraId] = {
        ...cache[cameraId],
        params: camera,
        svg: svg,
        webrtc: {pcQueue: []},
        exportQueue: [],
        curSource: -1,
        ptz: {},
    };
    log(`camera`, `cache`, cache);

    initHeader(camera,
        //переход в архив
        (isSwitched) => {
            log(`camera`, `callback, ${isSwitched}`, cameraId);
            switchArchive(cameraId, isSwitched);
        },
        //кнопка экспорта текущего кадра
        () => {
            let expReqBody = {"devId": camera.name, "source": cache[cameraId].curSource};

            if (cache[cameraId].curSource !== -1 && 'videoTime' in cache[cameraId]) {
                expReqBody.start = cache[cameraId].videoTime.utc().format('YYYYMMDD[T]HHmmss');
            }

            requestExport(expReqBody);
        });

    // увеличение во весь экран
    $svg.find(`.maximize-btn`).click(switchMaximizeHandler);
    $svg.parent(`.camera-frame`).dblclick(switchMaximizeHandler);
    document.addEventListener('fullscreenchange', fullScreenMaximizeListener);

    $svg.find(`text#caption`).text(`${camera.displayName}`);
    $svg.find(`text.camera-caption`).text(`${camera.displayName}`);

    log(`camera`, `header set for camera`, cameraId);

    // check and display private mask (live)
    if ('privacyMask' in camera && camera.privacyMask.params.protectLive) {
        if (!$svg.parent().hasClass('params-camera-wrapper')) {
            displayPrivateMask(camera, $svg);
        }
    }

    // check ptz control permissions
    checkPermissions('camera_control', 'ptz').then(allowed => {
        log(`camera`, 'received answer check permissions');
        if (allowed) {
            let $ptzBtn = $svg.find(`g.ptz-btn`);
            if (camera.ptzIsActive) {
                $ptzBtn.removeClass('disabled');
                $ptzBtn.off('click');
                $ptzBtn.on('click', (e) => {
                    switchPtz(cameraId, ptzCb);
                })
            } else {
                $ptzBtn.addClass('disabled');
            }
        }
    })

    cache[cameraId].events = wsMsgHandler(cameraId, $svg);
    subscribeWsMsg(cache[cameraId].events, cameraId);
    subscribeDev(cameraId);

    return true;
}

function switchMaximizeHandler(e) {
    let $svg = $(e.target).parents(`svg.camera`);
    let $camWrap = $(e.target).parents(`div.camera-frame`);
    let devId = $camWrap.find(`svg.camera`).attr('id');
    let $btn = $camWrap.find(`.maximize-btn`);

    if (hasFullscreenElement()) {
        $btn.removeClass('active')
        fullscreenOff();
        $('.camera-frame').removeClass('fullscreen');
    } else {
        if (fullscreenEnabled() && !!$camWrap.length) {
            $btn.addClass('active')
            fullscreenOn($camWrap[0]);
            $('.camera-frame').addClass('fullscreen');
        }
    }

    if ($svg.attr('data-mode') === 'archive') {
        let $exSpCnt = $(cache[devId].svg).find(`.speed-control`);
        let start = cache[devId].archiveInterval.start;
        let end = cache[devId].archiveInterval.end;
        let archNo = cache[devId].curSource;
        let data = cache[devId].timeLine.data();
        /*displayArchiveInfo(devId, start, end, archNo).then(() => {//todo
            $exSpCnt.remove();
        });*/

        createTimeline($svg[0], devId, archNo, data, start, end, !!getFullscreenElement() ? document.body : $camWrap[0]); // инверсия
    }

    clearWidgetAlerts()
}

function fullScreenMaximizeListener(e) {
    $(`.widget-alert-wr`).remove();

    let $camWrap = $(e.target);
    let $btn = $camWrap.find(`.maximize-btn`);
    hasFullscreenElement() ? $btn.addClass('active') : $btn.removeClass('active');
}

function wsMsgHandler(cameraId, $svg) {
    return function (msg) {
        switch (msg.type) {
            case 'reconnect':
                log(`camera`, 're-subscribe on ws reconnecting', cameraId);

                subscribeDev(cameraId);
                break;

            case 'state':
                if (msg.data.devId === cameraId && msg.type === 'state') {
                    let $border = $svg.find(`.ptz-area`);
                    let $bell = $svg.find(`.alarm-bell`);

                    if (hasFault(msg.data.state)) {
                        $border.addClass('fault');
                    } else {
                        $border.removeClass('fault');
                    }

                    if (hasAlarm(msg.data.state)) {
                        $border.addClass('alarm');
                        $bell.addClass(`active`);
                        $bell.on('click', () => {
                            fetchData(`/device/state/change/${cameraId}/normal`)
                        })
                    } else {
                        $border.removeClass('alarm');
                        $bell.removeClass(`active`);
                        $bell.off();
                    }

                    if (hasMotion(msg.data.state)) {
                        $svg.find(`.motion-icon`).show();
                    } else {
                        $svg.find(`.motion-icon`).hide();
                    }

                    if (hasAttention(msg.data.state)) {
                        $svg.find(`.share-btn`).addClass('active');
                    } else {
                        $svg.find(`.share-btn`).removeClass('active');
                    }
                }
                break;

            case 'ptz_occupation':
                if (msg.data.devId !== cameraId) {
                    break;
                }

                if (appSession.cid !== msg.data.wsCid) {
                    if (msg.data.isOccupied && appSession.priority <= msg.data.prio) {
                        blockPtz(cameraId, msg.data.user);
                    } else if (msg.data.isOccupied && appSession.priority > msg.data.prio) {
                        informPtzUsed(cameraId);
                    } else if (!msg.data.isOccupied) {
                        unblockPtz(cameraId);
                    }
                } else if (!msg.data.isOccupied) {
                    let mode = $svg.attr(`data-mode`);
                    if (mode.includes('ptz')) {
                        switchPtzOff(cameraId, $svg);

                        new Alert({
                            parent: $svg.parent()[0],
                            text: `Был осуществлён выход из режима PTZ из-за длительного бездействия`
                        }).display();
                    }
                }
                break;

            case 'ptz_ask_user':
                if (msg.data.devId !== cameraId) {
                    break;
                }
                log('camera', 'ask user', msg.data);
                $svg.parent().find(`.camera-dialog`).remove();
                new Dialog({
                    parent: $svg.parent()[0],
                    text: msg.data.eventStatus === 'new' ?
                        `Произошло событие: ${msg.data.eventText}. Желаете повернуть камеру на его источник?` :
                        msg.data.eventStatus === 'changed' ? 'Сменился источник приоритетного события, желаете повернуть камеру на него?' :
                            `Все источники событий камеры вернулись в исходное состояние. Желаете вернуть камеру в исходное положение?`,
                    ApplyText: 'Повернуть',
                    CancelText: 'Не нужно',
                    onApply: async () => {
                        moveToPosition(cameraId, msg.data.ptzPos);
                        sendMsg({
                            type: 'ptz_hide_dialog',
                            data: cameraId,
                        });
                    }
                }).display();

                break;

            case 'ptz_hide_dialog':
                log(`camera`, `hide ptz move action dialog by remote`);
                $svg.parent().find(`.camera-dialog`).remove();
        }
    }
}

export async function connectToServer(serverUrl) {
    if (serverUrl !== undefined) {
        log(`camera`, `connecting to server`, serverUrl);

        let newSrvUrl = new URL(serverUrl);

        // setWsPort(newSrvUrl.port);
        setServer(serverUrl);
    }

    if (appSession === undefined) {
        await requestAppSession()
    }

    let iceData = (await fetchData('/settings/stun')).payload;
    if (iceData !== undefined && iceData.servers !== undefined && Array.isArray(iceData.servers) && iceData.servers.length > 0) {
        setIceservers(iceData.servers);
    }

    if (!isWSCreated()) {
        openWebSocket();
    }
}

/**
 * @deprecated
 */
export function closeAllCamerasConnection() {
    for (let cam of Object.keys(cache)) {
        closeCameraConnection(cam);
    }
}

/***
 * @deprecated  Закрывает WebRtc поток и соединение по Websocket с сервером, если нет активных камер с указанного сервера.
 * @param cameraID
 * @param serverUrl
 */
export function closeCameraConnection(cameraID, serverUrl) {
    closeCamera(cameraID);

    if (Object.keys(cache).length === 0) {
        closeWebSocket();
        clearData();
    }
}

/**
 * @deprecated
 * @param camId
 */
export function closeCamera(camId) {
    log(`camera`, 'close camera', camId);

    checkAndSwitchPtzOff(camId);
    checkAndSwitchArchiveOff(camId);
    // stopWebRtc(camId);
    stopMse(camId);
    if (!!cache[camId]?.events) {
        unsubscribeWsMsg(cache[camId]?.events);
    }
    unSubscribeDev(camId);

    delete cache[camId];
}

export function stopLoading($svg) {
    $svg.removeClass('loading');
}

export function testAlert() {
   $(`svg.camera`).each(function () {
       new Alert({
           parent: $(this).parent()[0],
           text: `Тестовое сообщение для проверки работы всплывающих сообщений Тестовое сообщение для проверки работы всплывающих сообщений Тестовое сообщение для проверки работы всплывающих сообщений`
       }).display();
   });
}

export function clearWidgetAlerts(e) {
    $(`.widget-alert-wr`).remove();
}