import {log} from "../common/app";

export class Dialog {
    el;

    constructor(conf) {
        this.parent = conf.parent || document.body;
        this.text = conf.text || 'текст не задан';
        this.onApply = conf.onApply || function (){}
        this.ApplyText = conf.ApplyText || "Да";
        this.onCancel = conf.onCancel || function (){}
        this.CancelText = conf.CancelText || "Нет";
    }

    display() {
        this.el = document.createElement('div');
        this.el.classList.add('camera-dialog');

        let body = document.createElement('div');
        body.classList.add('dialog-body');
        body.append(this.text);
        this.el.append(body);

        let btns = document.createElement('div');
        btns.classList.add('dialog-buttons');
        btns.classList.add('mt-5');
        this.el.append(btns);

        let okBtn = document.createElement('button');
        okBtn.classList.add('btn');
        okBtn.classList.add('btn-primary');
        okBtn.innerText = this.ApplyText;
        okBtn.addEventListener('click', () => {
            this.onApply();
            this.el.remove();
        });
        btns.append(okBtn);

        let cancelBtn = document.createElement('button');
        cancelBtn.classList.add('btn');
        cancelBtn.classList.add('btn-secondary');
        cancelBtn.innerText = this.CancelText;
        cancelBtn.addEventListener('click', () => {
            this.onCancel();
            this.el.remove();
        });
        btns.append(cancelBtn);

        this.parent.insertAdjacentElement('beforeend', this.el);
        this.attachEvents();
    }

    attachEvents() {

    }
}